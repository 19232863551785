import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { getUser } from 'redux/selectors';
import { apiEntryTypeMap } from '../../common/types/apiEntryTypes';

class LocalCache {
  user = {};

  nameEQ = '';

  ca = '';

  jwt = '';

  page = '';

  constructor(page, user) {
    this.page = page;
    if (user) {
      this.user = user;
    } else {
      this.user = useSelector(getUser);
    }
  }

  getCached(key, defaultReturnValue) {
    if (this.user?.id.length > 0) {
      this.filters = window.localStorage.getItem(`filters-${this.user?.id}`);
      this.filters = JSON.parse(this.filters?.length > 0 ? this.filters : '{}');

      return get(this.filters, `${this.page}.${key}`, defaultReturnValue);
    }

    return defaultReturnValue;
  }

  getActiveFiltersAmount() {
    let activeFiltersCount = 0;
    if (this.user?.id.length > 0) {
      this.filters = window.localStorage.getItem(`filters-${this.user?.id}`);
      this.filters = JSON.parse(this.filters?.length > 0 ? this.filters : '{}');

      const updatedFilters = { ...this.filters?.crewSchedulingV2 };
      delete updatedFilters.selectedTimelines;
      delete updatedFilters.currentView;

      const { activeCrewSchedulingMobileTab } = updatedFilters
      if (activeCrewSchedulingMobileTab === apiEntryTypeMap.AircraftScheduleEntries) {
        delete updatedFilters.selectedCrewMemberIds;
        delete updatedFilters.selectedCrewCertification;
        delete updatedFilters.selectedCrewMemberBases;
      } else if (
        activeCrewSchedulingMobileTab === apiEntryTypeMap.CrewScheduleEntries ||
        activeCrewSchedulingMobileTab === apiEntryTypeMap.DutyScheduleEntries ||
        activeCrewSchedulingMobileTab === apiEntryTypeMap.DutyTimeEntries
      ) {
        delete updatedFilters.selectedAircraftBases;
        delete updatedFilters.selectedAircraftIdentifiers;
        delete updatedFilters.selectedAircraftTypes;
      }
      delete updatedFilters.activeCrewSchedulingMobileTab

      Object.values(updatedFilters || {}).forEach(value => {
        if (
          (value && !Array.isArray(value)) ||
          (value && Array.isArray(value) && value.length > 0)
        ) {
          activeFiltersCount += 1;
        }
      });
    }
    return activeFiltersCount;
  }

  async setCached(key, value) {
    // this.jwt = this.getCookie('airtrail_jwt_payload')
    if (this.user?.id.length > 0) {
      this.filters = window.localStorage.getItem(`filters-${this.user?.id}`);
      this.filters = JSON.parse(this.filters?.length > 0 ? this.filters : '{}');
      if (!this.filters[this.page]) {
        this.filters[this.page] = {};
      }
      this.filters[this.page][key] = value;
      window.localStorage.setItem(`filters-${this.user?.id}`, JSON.stringify(this.filters));
    }
  }
}

export default LocalCache;
