import { all, call, put, take, fork, takeEvery, select } from 'redux-saga/effects';
import { actionTypes } from './actions';
import actions from './actions';
import api from '../../services/api';
import { ApiTypes, CommonTypes } from 'models';
import userActions from '../user/actions';
import { initLD } from './workers/initLDWorker';
import { loadPilotsWorker } from './workers/loadPilotsWorker';
import { loadAircraftWorker } from './workers/loadAircraftWorker';
import { initCertTypesWorker } from './workers/initCertTypesWorker';
import { loadCustomCurrencyItemTemplates } from './workers/loadCustomCurrencyItemTemplates';
import { loadCompanyScheduleTypesWorker } from './workers/loadCompanyScheduleTypesWorker';
import { loadCompanyDocumentsWorker } from './workers/loadCompanyDocuments';
import { CompanyClient } from '../../common/types/client';
import { ClientContract } from '../../common/types/ClientContract';
import { loadMaintenanceWorker } from './workers/loadMaintenanceWorker';

function* fetchSubPartsList() {
  const {
    data: { Data },
  } = yield call(api.get, `v1/regulations/subparts/list`, {
    params: {
      order: 'subpart asc',
      show_in_modal: true,
    },
  });

  yield put({
    type: actionTypes.SET_STATE,
    payload: {
      defaultSubParts: Data || [],
    },
  });
}

function* fetchCompanyClients(companyID: string, limit: number = 1000, page: number = 1) {
  try {
    const response: ApiTypes.ResponseGenerator = yield call(
      api.get,
      `/v1/companies/${companyID}/clients`,
      { params: { limit, page } },
    );
    const { Count, Total, Data } = response.data;

    return { loadedCount: Count, total: Total, Data };
  } catch (error) {
    console.error('Failed to fetch clients:', error);
    // yield put(actions.fetchClientsFailed(error));
    return { loadedCount: 0, total: 0, Data: [] };
  }
}

function* fetchAllCompanyClients(companyID: string) {
  try {
    let page = 1;
    let totalClients: number;
    let loadedClients: number = 0;

    do {
      const { loadedCount, total, Data } = yield call(fetchCompanyClients, companyID, 1000, page);
      loadedClients += loadedCount;
      totalClients = total;
      const clients: CompanyClient[] = Data;
      yield put(actions.loadCompanyClients(clients));
      const clientContracts = clients.reduce((acc, client) => {
        if (client.ClientContracts?.length > 0) {
          return acc.concat(client.ClientContracts);
        }
        return acc;
      }, [] as ClientContract[]);
      yield put(actions.setClientContracts(clientContracts));
      yield put(actions.defaultCompanyClientsLoaded());
      page += 1;
    } while (loadedClients < totalClients);

    yield put(actions.loadCompanyClientsFinished());
  } catch (error) {
    console.error('Failed to fetch all clients:', error);
  }
}

function* fetchDefaultAirportsWorker() {
  const response: ApiTypes.ResponseGenerator = yield call(api.get, `/v1/airports`, {
    params: { limit: 10 },
  });
  const {
    data: { Data },
  } = response;

  const defaultAirports = Data.map((airport: CommonTypes.AirPort) => {
    return {
      text: `${airport.ICAOCode}`,
      value: airport.ID,
      tz: airport.Timezone,
    };
  });

  yield put({
    type: actionTypes.SET_LOAD_DEFAULT_AIRPORTS_TO_STATE,
    payload: defaultAirports,
  });
}

function* fetchDefaultAirportsWatcher() {
  yield take(actionTypes.LOAD_DEFAULT_AIRPORTS);
  yield call(fetchDefaultAirportsWorker);
}

function* watchCompanyLoaded() {
  const company = yield select(({ user: { Company } }) => Company);
  const { ID, Name } = company;
  yield all([
    call(initLD, ID, Name),
    call(fetchSubPartsList),
    call(fetchAllCompanyClients, ID),
    call(loadPilotsWorker, ID),
    call(loadMaintenanceWorker, ID),
    call(loadAircraftWorker, ID),
    call(loadCustomCurrencyItemTemplates),
    call(loadCompanyScheduleTypesWorker, ID),
    call(loadCompanyDocumentsWorker),
  ]);
}

export default function* rootSaga() {
  yield all([
    fork(fetchDefaultAirportsWatcher),
    takeEvery(userActions.SET_COMPANY, watchCompanyLoaded),
    takeEvery(actionTypes.SET_COMPANY_AIRCRAFT_LIST, initCertTypesWorker),
  ]);
}
