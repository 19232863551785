import store from 'store';
import actions from './actions';

const STORED_SETTINGS = storedSettings => {
  const settings = {};
  Object.keys(storedSettings).forEach(key => {
    const item = store.get(`app.settings.${key}`);
    settings[key] = typeof item !== 'undefined' ? item : storedSettings[key];
  });
  return settings;
};

const initialState = {
  ...STORED_SETTINGS({
    isMobileView: false,
    isHideMobileMenu: false,
    isMobileMenuOpen: false,
    isMobileProfileMenuOpen: false,
    isJustLoggedIn: true,
    isSettingsOpen: false,
    isMenuCollapsed: false,
    isBorderless: true,
    isSquaredBorders: false,
    isFixedWidth: false,
    isMenuShadow: true,
    locale: 'en-US',
  }),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    case actions.SHOW_MOBILE_MENU:
      return { ...state, isHideMobileMenu: false };
    case actions.HIDE_MOBILE_MENU:
      return { ...state, isHideMobileMenu: true };
    default:
      return state;
  }
}
