export type EntryType = MaintenanceTimelineEntryType;

export enum MaintenanceTimelineEntryType {
  MaintenanceCrew = 'maintenance-crew-schedule',
}

const sortOrder = [MaintenanceTimelineEntryType.MaintenanceCrew];

const compareTimelines = (
  a: MaintenanceTimelineEntryType,
  b: MaintenanceTimelineEntryType,
): number => {
  return sortOrder.indexOf(a) - sortOrder.indexOf(b);
};

export const sortMaintenanceTimelineEntryTypes = (
  timelines: MaintenanceTimelineEntryType[],
): MaintenanceTimelineEntryType[] => {
  return timelines.sort(compareTimelines);
};
