import MODAL_MODE from '../../../pages/data/common/constants';
import { Qualification } from '../../../pages/data/organization/tracked-items/constants';

const actions = {
  OPEN_MODAL: "pages/qualifications/OPEN_MODAL",
  CLOSE_MODAL: "pages/qualifications/CLOSE_MODAL",
  SET_QUALIFICATIONS: "pages/qualifications/SET_QUALIFICATIONS"
}

export interface OpenQualificationModalAction {
  type: typeof actions.OPEN_MODAL;
  payload: { mode: MODAL_MODE };
}

export interface CloseQualificationModalAction {
  type: typeof actions.CLOSE_MODAL;
}

export interface SetQualificationsAction {
  type: typeof actions.SET_QUALIFICATIONS;
  payload: { qualifications: Qualification[] }
}

export const openQualificationModalAction = (mode: MODAL_MODE): OpenQualificationModalAction => ({
  type: actions.OPEN_MODAL,
  payload: { mode },
});

export const closeQualificationModalAction = (): CloseQualificationModalAction => ({
  type: actions.CLOSE_MODAL,
});

export const setQualificationsAction = (qualifications: Qualification[]): SetQualificationsAction => ({
  type: actions.SET_QUALIFICATIONS,
  payload: { qualifications },
});


export default actions
