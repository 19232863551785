import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import actions, {
  LoadEntriesAction,
  setEntryTagToPilotIds,
  setUpdatedCrewScheduleEntry,
  setUpdatedDutyTimeEntry,
  setUpdatedScheduleEntry,
} from '../actions';
import api from '../../../../services/api';
import moment from 'moment/moment';
import { flatMap, groupBy, uniqBy } from 'lodash';
import { getCompanyID, getDutyTimeModalEntryModal } from '../../../selectors';
import { Config } from '../../../../pages/data/organization/crew-scheduling-v3/legacy/utils/Config';
import { ApiTypes } from 'models/api';
import Pilot = ApiTypes.Pilot;
import { CrewScheduleEntry } from './types/CrewScheduleEntry';
import { entryTypeMapping } from '../../../../common/types/apiEntryTypes';
import { EntryType, validTypes } from '../../../../common/types/entryTypes';

export const fetchAndUpdateEntriesRequest = (config: Config, entryType: EntryType) => ({
  type: actions.UPDATE_ENTRIES_BY_REQUEST,
  payload: { config, entryType },
});

export const fetchAndUpdateEntriesFailure = error => ({
  type: actions.UPDATE_ENTRIES_BY_FAILURE,
  payload: { error },
});

function* fetchAndUpdateEntries(action: ReturnType<typeof fetchAndUpdateEntriesRequest>) {
  const { config, entryType } = action.payload;

  const companyID = yield select(getCompanyID);
  const {
    selectedPilots,
    crewScheduleEntries,
    dutyScheduleEntries,
    dutyTimeEntries,
  } = yield select(({ pages: { crewSchedulingV2 } }) => crewSchedulingV2);

  if (!validTypes.includes(entryType)) {
    yield put({
      type: actions.UPDATE_ENTRIES_BY_FAILURE,
      payload: new Error('Invalid entry type'),
    });
    return;
  }

  const { preferred: { lastUpdatedAtArray = [] } = {}, startTime, endTime } = config || {};
  let type = '';
  let pilotsToFetch = selectedPilots;
  if (config?.preferred?.pilots?.length > 0) {
    pilotsToFetch = config.preferred.pilots;
    type = config.preferred.type;
  }

  let entriesData: any[] = [];
  if (entryType === 'crewschedule') {
    entriesData = crewScheduleEntries;
  } else if (entryType === 'duty-schedule') {
    entriesData = dutyScheduleEntries;
  } else if (entryType === 'duty-times') {
    entriesData = dutyTimeEntries;
  }

  const pilotIds: number[] = pilotsToFetch.map((pilot: Pilot) => pilot.ID);
  const lastUpdatedAtArrayFormatted: string[] = lastUpdatedAtArray.map(date =>
    moment(date || undefined)
      .subtract(1, 'hour')
      .utc()
      .format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
  );

  const baseApiParams = {
    end_time__gt: startTime.format(),
    start_time__lt: endTime.format(),
    ongoing: true,
    limit: -1,
    user_ids: pilotIds,
  };

  if (type === 'replace') {
    try {
      const {
        data: { Data },
      } = yield call(api.get, `/v1/companies/${companyID}/get-fresh-entries`, {
        params: {
          ...baseApiParams,
          last_updated_at_array: lastUpdatedAtArrayFormatted,
          entry_type: entryType,
        },
      });

      const resultEntries = Data[entryTypeMapping[entryType]] || [];
      const newEntryIDs = resultEntries.map(entry => entry.ID);
      const filteredEntries = entriesData.filter(entry => !newEntryIDs.includes(entry.ID));
      const groupByUser = groupBy([...filteredEntries], 'UserID');
      const resultGroupByUser = groupBy([...resultEntries], 'UserID');

      Object.keys(resultGroupByUser).forEach(key => {
        groupByUser[key] = [...resultGroupByUser[key]];
      });

      entriesData = flatMap(groupByUser);
    } catch (error) {
      yield put({ type: actions.UPDATE_ENTRIES_BY_FAILURE, payload: error });
      return;
    }
  } else {
    if (type !== 'exclude') {
      try {
        const {
          data: { Data },
        } = yield call(api.get, `/v1/companies/${companyID}/${entryType}`, {
          params: baseApiParams,
        });
        if (type === 'include') {
          entriesData = [...entriesData, ...Data];
          // check on the duplicates
          entriesData = uniqBy(entriesData, e => e.ID);
        } else {
          entriesData = Data;
        }
      } catch (error) {
        yield put({ type: actions.UPDATE_ENTRIES_BY_FAILURE, payload: error });
        return;
      }
    } else {
      entriesData = entriesData.filter(entry => !pilotIds.includes(entry.UserID));
    }
  }

  return entriesData;
}

const collectionNameByEntryType: Record<EntryType, string> = {
  'duty-schedule': 'dutyScheduleEntries',
  'duty-times': 'dutyTimeEntries',
  crewschedule: 'crewScheduleEntries',
  'aircraft-schedule': 'aircraftScheduleEntries',
  'maintenance-crew-schedule': 'maintenanceScheduleEntries',
};

export function* loadEntries(action: LoadEntriesAction) {
  const {
    publishedVersions,
    preferredVersion,
    companyScheduleVersion,
    selectedPilots,
  } = yield select(({ pages: { crewSchedulingV2 } }) => crewSchedulingV2);
  const companySettings = yield select(({ user: { Company } }) => Company.Settings);

  if (selectedPilots.length === 0) {
    return;
  }

  const config = action.payload;
  const entryType = action.payload.entryType;
  const collection = collectionNameByEntryType[entryType];

  if (config.isShowLoading) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        [`${collection}IsUpdating`]: true,
      },
    });
  }

  if (
    entryType !== 'duty-times' &&
    companySettings?.Versioning &&
    publishedVersions?.length > 0 &&
    preferredVersion &&
    preferredVersion !== 'latest' &&
    companyScheduleVersion
  ) {
    let selectedVersion = preferredVersion;
    if (preferredVersion === 'current') {
      selectedVersion = companyScheduleVersion.CurrentVersion;
    }
    const selectedPublishedVersion = publishedVersions?.find(
      pv => pv.Version === parseInt(selectedVersion, 10),
    );

    if (
      selectedPublishedVersion?.VisibleStart &&
      moment(selectedPublishedVersion?.VisibleStart).valueOf() > moment(config.startTime).valueOf()
    ) {
      config.startTime = moment(selectedPublishedVersion?.VisibleStart);
    }

    if (
      selectedPublishedVersion?.VisibleEnd &&
      moment(selectedPublishedVersion?.VisibleEnd).valueOf() < moment(config.endTime).valueOf()
    ) {
      config.endTime = moment(selectedPublishedVersion?.VisibleEnd);
    }
  }

  const entries = yield call(fetchAndUpdateEntries, {
    type: actions.UPDATE_ENTRIES_BY_REQUEST,
    payload: { config, entryType: entryType },
  });

  yield put({
    type: actions.SET_STATE,
    payload: {
      [collection]: entries,
      [`${collection}IsUpdating`]: false,
    },
  });

  if (config.isUpdateCurrent) {
    if ('crewschedule' === entryType) {
      const { currentScheduleEntry } = yield select(
        ({ pages: { crewSchedulingV2 } }) => crewSchedulingV2,
      );

      const updatedEntry = entries.find(entry => entry.ID === currentScheduleEntry.ID);
      if (updatedEntry) {
        yield put(
          setUpdatedCrewScheduleEntry({
            type: 'crew-schedule-entry',
            action: 'update',
            payload: updatedEntry,
          }),
        );
      }
    } else if ('duty-schedule' === entryType) {
      const { currentDutyScheduleEntry } = yield select(
        ({ pages: { crewSchedulingV2 } }) => crewSchedulingV2,
      );

      const updatedEntry = entries.find(entry => entry.ID === currentDutyScheduleEntry.ID);
      if (updatedEntry) {
        yield put(
          setUpdatedScheduleEntry({
            type: 'duty-schedule-entry',
            action: 'update',
            payload: updatedEntry,
          }),
        );
      }
    } else if ('duty-times' === entryType) {
      const { currentDutyTimeEntry } = yield select(getDutyTimeModalEntryModal);

      const updatedEntry = entries.find(entry => entry.ID === currentDutyTimeEntry.ID);
      if (updatedEntry) {
        yield put(
          setUpdatedDutyTimeEntry({
            type: 'duty-time-entry',
            action: 'update',
            payload: updatedEntry,
          }),
        );
      }
    }
  }

  if (collection === collectionNameByEntryType.crewschedule) {
    const entryTagToPilotsIDs: Record<string, string[]> = entries.reduce((acc, entry) => {
      const { Tag, UserID } = entry as CrewScheduleEntry;
      if (Tag) {
        if (!acc[Tag]) {
          acc[Tag] = [];
        } else if (acc[Tag].includes(UserID)) {
          return acc;
        }

        acc[Tag].push(UserID);
      }
      return acc;
    }, {});
    yield put(setEntryTagToPilotIds(entryTagToPilotsIDs));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.UPDATE_ENTRIES_BY_REQUEST, fetchAndUpdateEntries),
    takeEvery(actions.LOAD_ENTRIES, loadEntries),
  ]);
}
