import { CrewScheduleFilter } from '../constants/filters';

export enum AllowAction {
  allowPrioritizeIFRHours = 'allowPrioritizeIFRHours',
  addAircraftContract = 'addAircraftContract',
  displayDateRangeCalculator = 'displayDateRangeCalculator',
  displayReserveOptionsInCrewSchedule = 'displayReserveOptionsInCrewSchedule',
  displayFDPOptionsInCrewSchedule = 'displayFDPOptionsInCrewSchedule',
  allowPilotDutyManagement = 'allowPilotDutyManagement',
  allowPilotDutyScheduleManagement = 'allowPilotDutyScheduleManagement',
  allowPilotsToEditHistoricalDutyTimes = 'allowPilotsToEditHistoricalDutyTimes',
  addPilotContract = 'addPilotContract',
  addScheduleEntry = 'addScheduleEntry',
  addMaintenanceEntry = 'addMaintenanceEntry',
  addDutyEntry = 'addDutyEntry',
  addDutyTime = 'addDutyTime',
  updateScheduleEntry = 'updateScheduleEntry',
  updateMaintenanceEntry = 'updateMaintenanceEntry',
  updateDutyEntry = 'updateDutyEntry',
  updateDutyTime = 'updateDutyTime',
  deleteScheduleEntry = 'deleteScheduleEntry',
  deleteDutyEntry = 'deleteDutyEntry',
  deleteDutyTime = 'deleteDutyTime',
  deleteMaintenanceEntry = 'deleteMaintenanceEntry',
  copyAndPasteEntry = 'copyAndPasteEntry',
  overrideEntryCompliance = 'overrideEntryCompliance',
  allowEditDutyScheduleVFRInput = 'allowEditDutyScheduleVFRInput',
  allowEditDutyTimeVFRInput = 'allowEditDutyTimeVFRInput',
  allowEditDutyScheduleIFRInput = 'allowEditDutyScheduleIFRInput',
  allowEditDutyTimeIFRInput = 'allowEditDutyTimeIFRInput',
  allowEditSubParts = 'allowEditSubParts',
  displaySinglePilot = 'displaySinglePilot',
  displayAllDayVFR = 'displayAllDayVFR',
  allowEditFlightDetails = 'allowEditFlightDetails',
  createDutyTimeBeforeMostRecent = 'createDutyTimeBeforeMostRecent',
  allowDutyScheduleEditPilot = 'allowDutyScheduleEditPilot',
  tracksOnCall = 'tracksOnCall',
}

export interface Actions {
  [AllowAction.allowPrioritizeIFRHours]: boolean;
  [AllowAction.addAircraftContract]: boolean;
  [AllowAction.displayDateRangeCalculator]: boolean;
  [AllowAction.displayReserveOptionsInCrewSchedule]: boolean;
  [AllowAction.displayFDPOptionsInCrewSchedule]: boolean;
  [AllowAction.allowPilotDutyManagement]: boolean;
  [AllowAction.allowPilotsToEditHistoricalDutyTimes]: boolean;
  [AllowAction.addPilotContract]: boolean;
  [AllowAction.addScheduleEntry]: boolean;
  [AllowAction.addMaintenanceEntry]: boolean;
  [AllowAction.addDutyEntry]: boolean;
  [AllowAction.addDutyTime]: boolean;
  [AllowAction.updateScheduleEntry]: boolean;
  [AllowAction.updateMaintenanceEntry]: boolean;
  [AllowAction.updateDutyEntry]: boolean;
  [AllowAction.updateDutyTime]: boolean;
  [AllowAction.deleteScheduleEntry]: boolean;
  [AllowAction.deleteDutyEntry]: boolean;
  [AllowAction.deleteDutyTime]: boolean;
  [AllowAction.deleteMaintenanceEntry]: boolean;
  [AllowAction.copyAndPasteEntry]: boolean;
  [AllowAction.overrideEntryCompliance]: boolean;
  [AllowAction.allowEditDutyScheduleVFRInput]: boolean;
  [AllowAction.allowEditDutyScheduleIFRInput]: boolean;
  [AllowAction.allowEditSubParts]: boolean;
  [AllowAction.displaySinglePilot]: boolean;
  [AllowAction.displayAllDayVFR]: boolean;
  [AllowAction.allowEditFlightDetails]: boolean;
  [AllowAction.createDutyTimeBeforeMostRecent]: boolean;
  [AllowAction.allowPilotDutyScheduleManagement]: boolean;
  [AllowAction.allowEditDutyTimeVFRInput]: boolean;
  [AllowAction.allowEditDutyTimeIFRInput]: boolean;
  [AllowAction.allowDutyScheduleEditPilot]: boolean;
  [AllowAction.tracksOnCall]: boolean;
}

export interface Timelines {
  aircraftTimeline: boolean;
  viewAircraftContracts: boolean;
  viewAircraftEntries: boolean;
  crewTimeline: boolean;
}

export interface Filters {
  [CrewScheduleFilter.RESOURCE_TYPE]: boolean;
  [CrewScheduleFilter.RESOURCE]: boolean;
  [CrewScheduleFilter.CREW_MEMBER]: boolean;
  [CrewScheduleFilter.MAINTENANCE_MEMBER]: boolean;
  [CrewScheduleFilter.CREW_CERTIFICATION]: boolean;
  [CrewScheduleFilter.MAINTENANCE_CERTIFICATION]: boolean;
  [CrewScheduleFilter.CREW_MEMBER_BASE]: boolean;
  [CrewScheduleFilter.RESOURCE_BASE]: boolean;
  [CrewScheduleFilter.DISPLAY_OPTIONS]: boolean;
  [CrewScheduleFilter.CLIENT]: boolean;
  [CrewScheduleFilter.TAG]: boolean;
  [CrewScheduleFilter.CLIENT_CONTRACT]: boolean;
}

export interface CrewScheduleV2Permissions {
  filters: Filters;
  timelines: Timelines;
  actions: Actions;
}

export interface Permissions {
  crewScheduleV2: CrewScheduleV2Permissions;
}
