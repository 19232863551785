import { message } from 'antd';
import api from '../../../services/api';

export const updateProfileTimezone = async (value, userID) => {
  const {
    data: { Data },
  } = await api.patch(
    `/v1/users/${userID}/timezone/update`,
    {},
    {
      params: { timezone: value },
    },
  );
  if (Data) {
    message.success('Timezone updated successfully');
    window.location.reload();
  }
};
