import { Config } from '../../../pages/data/organization/crew-scheduling-v3/legacy/utils/Config';
import moment from 'moment/moment';
import { DutyValidationUpdateDto, EntryType } from '../../../common/types/entryTypes';
import { PreferredVersion } from '../../../common/types/preferredVersoon';
import { Tag, SelectedTimeline, ValidationStatus } from './types/types';
import { CrewTimelineEntryType } from '../../../common/types/timeline/CrewTimelineEntryType';
import { DutyScheduleEntry } from './scheduleEntries/types/DutyScheduleEntry';

const actions = {
  SET_STATE: 'pages/company/crew-scheduling-v3/SET_STATE',
  SET_INITIAL_STATE: 'pages/company/crew-scheduling-v3/SET_INITIAL_STATE',
  SET_PUBLISHED_VERSION: 'pages/company/crew-scheduling-v3/SET_PUBLISHED_VERSION',
  SET_PUBLISHED_VERSION_RAW: 'pages/company/crew-scheduling-v3/SET_PUBLISHED_VERSION_RAW',
  LOAD_CREW_SCHEDULE_COMPONENT_DATA:
    'pages/company/crew-scheduling-v3/LOAD_CREW_SCHEDULE_COMPONENT_DATA',
  LOAD_ALL_PUBLISHED_VERSIONS: 'pages/company/crew-scheduling-v3/LOAD_ALL_PUBLISHED_VERSIONS',
  LOAD_PUBLISHED_VERSIONS: 'pages/company/crew-scheduling-v3/LOAD_PUBLISHED_VERSIONS',
  ADD_PUBLISHED_VERSION: 'pages/company/crew-scheduling-v3/ADD_PUBLISHED_VERSION',

  LOAD_PILOTS_SUCCESS: 'pages/company/crew-scheduling-v3/LOAD_PILOTS_SUCCESS',
  LOAD_SELECTED_PILOTS_SUCCESS: 'pages/company/crew-scheduling-v3/LOAD_SELECTED_PILOTS_SUCCESS',
  SET_PREV_SELECTED_PILOTS: 'pages/company/crew-scheduling-v3/SET_PREV_SELECTED_PILOTS',

  LOAD_PILOTS_ORDERED_BY_FIRST_NAME:
    'pages/company/crew-scheduling-v3/LOAD_PILOTS_ORDERED_BY_FIRST_NAME',

  LOAD_MAINTENANCE_SUCCESS: 'pages/company/crew-scheduling-v3/LOAD_MAINTENANCE_SUCCESS',
  LOAD_SELECTED_MAINTENANCE_SUCCESS:
    'pages/company/crew-scheduling-v3/LOAD_SELECTED_MAINTENANCE_SUCCESS',
  SET_PREV_SELECTED_MAINTENANCE: 'pages/company/crew-scheduling-v3/SET_PREV_SELECTED_MAINTENANCE',

  LOAD_MAINTENANCE_ORDERED_BY_FIRST_NAME:
    'pages/company/crew-scheduling-v3/LOAD_MAINTENANCE_ORDERED_BY_FIRST_NAME',

  SET_CURRENT_DUTY_SCHEDULE_ENTRY:
    'pages/company/crew-scheduling-v3/SET_CURRENT_DUTY_SCHEDULE_ENTRY',
  SET_CURRENT_SCHEDULE_ENTRY: 'pages/company/crew-scheduling-v3/SET_CURRENT_SCHEDULE_ENTRY',
  SET_CURRENT_MAINTENANCE_ENTRY: 'pages/company/crew-scheduling-v3/SET_CURRENT_MAINTENANCE_ENTRY',

  UPDATE_ENTRIES_BY_REQUEST: 'pages/company/crew-scheduling-v3/UPDATE_ENTRIES_BY_REQUEST',
  UPDATE_ENTRIES_BY_FAILURE: 'pages/company/crew-scheduling-v3/UPDATE_ENTRIES_BY_FAILURE',

  LOAD_ENTRIES: 'pages/company/crew-scheduling-v3/LOAD_ENTRIES',

  SET_COMPANY_SCHEDULE_VERSION: 'pages/company/crew-scheduling-v3/SET_COMPANY_SCHEDULE_VERSION',
  SET_PREFERRED_VERSION: 'pages/company/crew-scheduling-v3/SET_PREFERRED_VERSION',

  UPDATE_ENTRY_PERIOD: 'pages/company/crew-scheduling-v3/UPDATE_ENTRY_PERIOD',
  SET_COMPANY_TAGS: 'pages/company/crew-scheduling-v3/SET_COMPANY_TAGS',
  SET_ENTRY_TAG_TO_PILOT_IDS: 'pages/company/crew-scheduling-v3/SET_ENTRY_TAG_TO_PILOT_IDS',
  SET_SELECTED_TAGS: 'pages/company/crew-scheduling-v3/SET_SELECTED_TAGS',
  SET_SELECTED_CREW_MEMBER_IDS: 'pages/company/crew-scheduling-v3/SET_SELECTED_CREW_MEMBER_IDS',
  SET_SELECTED_MAINTENANCE_MEMBER_IDS:
    'pages/company/crew-scheduling-v3/SET_SELECTED_MAINTENANCE_MEMBER_IDS',

  SET_VALIDATING_DUTY_SCHEDULE_ENTRIES:
    'pages/company/crew-scheduling-v3/SET_VALIDATING_DUTY_SCHEDULE_ENTRIES',
  UPDATE_VALIDATING_DUTY_SCHEDULE_ENTRIES:
    'pages/company/crew-scheduling-v3/UPDATE_VALIDATING_DUTY_SCHEDULE_ENTRIES',
  VALIDATION_STARTED: 'pages/company/crew-scheduling-v3/VALIDATION_STARTED',
  VALIDATION_FINISHED: 'pages/company/crew-scheduling-v3/VALIDATION_FINISHED',
  SET_UPDATED_CREW_SCHEDULE_ENTRY:
    'pages/company/crew-scheduling-v3/SET_UPDATED_CREW_SCHEDULE_ENTRY',
  SET_UPDATED_SCHEDULE_ENTRY: 'pages/company/crew-scheduling-v3/SET_UPDATED_SCHEDULE_ENTRY',
  SET_UPDATED_DUTY_TIME_ENTRY: 'pages/company/crew-scheduling-v3/SET_UPDATED_DUTY_TIME_ENTRY',
  SET_SELECTED_TIMELINES: 'pages/company/crew-scheduling-v3/SET_SELECTED_TIMELINES',
  SET_SELECTED_AIRCRAFT_IDENTIFIERS:
    'pages/company/crew-scheduling-v3/SET_SELECTED_AIRCRAFT_IDENTIFIERS',
  SET_IS_AIRCRAFT_SCHEDULE_UPDATING:
    'pages/company/crew-scheduling-v3/SET_IS_AIRCRAFT_SCHEDULE_UPDATING',
  LOAD_AIRCRAFT_SCHEDULE_ENTRIES: 'pages/company/crew-scheduling-v3/LOAD_AIRCRAFT_SCHEDULE_ENTRIES',
  SET_IS_UPDATING_AIRCRAFT_CONTRACTS:
    'pages/company/crew-scheduling-v3/SET_IS_UPDATING_AIRCRAFT_CONTRACTS',
  LOAD_AIRCRAFT_CONTRACTS: 'pages/company/crew-scheduling-v3/LOAD_AIRCRAFT_CONTRACTS',
  CHECK_OVERLAPS: 'pages/company/crew-scheduling-v3/CHECK_OVERLAPS',
  GET_LOGS_FROM_FLIGHTS: 'pages/company/crew-scheduling-v3/GET_LOGS_FROM_FLIGHTS',
  RESET_CHECK_OVERLAPS: 'pages/company/crew-scheduling-v3/RESET_CHECK_OVERLAPS',
  RESET_LOGS_FROM_FLIGHTS: 'pages/company/crew-scheduling-v3/RESET_LOGS_FROM_FLIGHTS',
  SET_CREW_SORT_OPTION: 'pages/company/crew-scheduling-v3/SET_CREW_SORTER_OPTION',
  SET_MAINTENANCE_SORT_OPTION: 'pages/company/crew-scheduling-v3/SET_MAINTENANCE_SORT_OPTION',
  ON_VISIBLE_TIMELINE_PERIOD_CHANGE:
    'pages/company/crew-scheduling-v3/ON_VISIBLE_TIMELINE_PERIOD_CHANGE',
  TRIGGER_REAL_TIME_UPDATE_MGS: 'pages/company/crew-scheduling-v3/TRIGGER_REAL_TIME_UPDATE_MGS',
};

export default actions;

export interface CheckOverlapsAction {
  type: string;
  payload: {
    entryType: EntryType;
    entryID: string;
    from: moment.Moment;
    to: moment.Moment;
    userID: string;
  };
}

export interface AircraftLogsFromFlightsAction {
  type: string;
  payload: {
    startTime: moment.Moment;
    endTime: moment.Moment;
    userID: string;
  };
}

export interface SetPreferredVersionAction {
  type: typeof actions.SET_PREFERRED_VERSION;
  payload: PreferredVersion;
}
export interface SetCrewSortOption {
  type: typeof actions.SET_CREW_SORT_OPTION;
  payload: { crewSortOption: string };
}
export interface SetMaintenanceSortOption {
  type: typeof actions.SET_MAINTENANCE_SORT_OPTION;
  payload: { maintenanceSortOption: string };
}

export interface LoadEntriesAction {
  type: string;
  payload: Config & { entryType: EntryType };
}

export interface UpdateEntryPeriodAction {
  type: string;
  payload: [moment.Moment, moment.Moment];
}

export interface SetSelectedTagsAction {
  type: string;
  payload: {
    selectedTags: string[];
  };
}

export interface SetValidatingEntriesAction {
  type: string;
  payload: {
    validatingEntries: Record<string, ValidationStatus>;
  };
}

export interface ValidationStartedAction {
  type: string;
  payload: {
    entryType: EntryType;
    entryIDs: string[];
  };
}

export interface ValidationFinishedAction {
  type: string;
  payload: { entryType: EntryType; updates: DutyValidationUpdateDto[] };
}

export interface SetSelectedTimelinesAction {
  type: string;
  payload: { selectedTimelines: SelectedTimeline[] };
}

export const loadCrewScheduleComponentData = () => {
  return {
    type: actions.LOAD_CREW_SCHEDULE_COMPONENT_DATA,
  };
};

export const loadAllPublishedVersions = () => ({
  type: actions.LOAD_ALL_PUBLISHED_VERSIONS,
});

export const loadPublishedVersions = payload => ({
  type: actions.LOAD_PUBLISHED_VERSIONS,
  payload: payload,
});

export const addPublishedVersion = version => ({
  type: actions.ADD_PUBLISHED_VERSION,
  payload: version,
});

export const setCurrentDutyScheduleEntryAction = (entry: Partial<DutyScheduleEntry>) => {
  return {
    type: actions.SET_CURRENT_DUTY_SCHEDULE_ENTRY,
    payload: entry,
  };
};

export const setCurrentScheduleEntryAction = currentScheduleEntry => ({
  type: actions.SET_CURRENT_SCHEDULE_ENTRY,
  payload: { currentScheduleEntry },
});

export const setCurrentMaintenanceEntryAction = currentMaintenanceScheduleEntry => ({
  type: actions.SET_CURRENT_MAINTENANCE_ENTRY,
  payload: { currentMaintenanceScheduleEntry },
});

export const loadDutyTimesEntries = (config: Config): LoadEntriesAction => {
  return {
    type: actions.LOAD_ENTRIES,
    payload: { ...config, entryType: CrewTimelineEntryType.DutyTimes },
  };
};

export const loadDutyScheduleEntries = (config: Config): LoadEntriesAction => {
  return {
    type: actions.LOAD_ENTRIES,
    payload: { ...config, entryType: CrewTimelineEntryType.DutySchedule },
  };
};

export const loadCrewScheduleEntries = (config: Config): LoadEntriesAction => {
  return {
    type: actions.LOAD_ENTRIES,
    payload: { ...config, entryType: CrewTimelineEntryType.CrewSchedule },
  };
};

export const setPreferredVersion = (version: PreferredVersion): SetPreferredVersionAction => ({
  type: actions.SET_PREFERRED_VERSION,
  payload: version,
});

export const updateEntryPeriod = (
  period: [moment.Moment, moment.Moment],
): UpdateEntryPeriodAction => {
  return {
    type: actions.UPDATE_ENTRY_PERIOD,
    payload: period,
  };
};

export const setPrevSelectedPilots = prevSelectedPilots => {
  return {
    type: actions.SET_PREV_SELECTED_PILOTS,
    payload: { prevSelectedPilots },
  };
};

export const setCompanyTags = (tags: Tag[]) => {
  return {
    type: actions.SET_COMPANY_TAGS,
    payload: { companyTags: tags },
  };
};

export const setEntryTagToPilotIds = (entryTagToPilotsIDs: Record<string, string[]>) => {
  return {
    type: actions.SET_ENTRY_TAG_TO_PILOT_IDS,
    payload: { entryTagToPilotsIDs },
  };
};

export const setSelectedTagsAction = (selectedTags: string[]): SetSelectedTagsAction => {
  return {
    type: actions.SET_SELECTED_TAGS,
    payload: { selectedTags },
  };
};

export const setState = payload => ({
  type: actions.SET_STATE,
  payload,
});

export const setSelectedCrewMemberIds = (selectedCrewMemberIds: string[]) => {
  return {
    type: actions.SET_SELECTED_CREW_MEMBER_IDS,
    payload: { selectedCrewMemberIds },
  };
};

export const setSelectedMaintenanceMemberIds = (selectedMaintenanceMemberIds: string[]) => {
  return {
    type: actions.SET_SELECTED_MAINTENANCE_MEMBER_IDS,
    payload: { selectedMaintenanceMemberIds },
  };
};

export const setValidatingEntries = (
  validatingEntries: Record<string, ValidationStatus>,
): SetValidatingEntriesAction => {
  return {
    type: actions.SET_VALIDATING_DUTY_SCHEDULE_ENTRIES,
    payload: { validatingEntries },
  };
};

export const validationStarted = (
  entryType: EntryType,
  entryIDs: string[],
): ValidationStartedAction => {
  return {
    type: actions.VALIDATION_STARTED,
    payload: { entryType, entryIDs },
  };
};

export const validationFinished = (
  entryType: EntryType,
  updates: DutyValidationUpdateDto[],
): ValidationFinishedAction => {
  return {
    type: actions.VALIDATION_FINISHED,
    payload: { entryType, updates },
  };
};

export const setUpdatedCrewScheduleEntry = (payload: any | null) => ({
  type: actions.SET_UPDATED_CREW_SCHEDULE_ENTRY,
  payload: { updatedCrewScheduleEntry: payload },
});

export const setUpdatedScheduleEntry = (payload: any | null) => ({
  type: actions.SET_UPDATED_SCHEDULE_ENTRY,
  payload: { updatedScheduleEntry: payload },
});

export const setUpdatedDutyTimeEntry = (payload: any | null) => ({
  type: actions.SET_UPDATED_DUTY_TIME_ENTRY,
  payload: { updatedDutyTimeEntry: payload },
});

export const setSelectedAircraftIdentifiers = (tails: string[]) => {
  return {
    type: actions.SET_SELECTED_AIRCRAFT_IDENTIFIERS,
    payload: { selectedAircraftIdentifiers: tails },
  };
};

export const setCrewSortOption = (option: string): SetCrewSortOption => ({
  type: actions.SET_CREW_SORT_OPTION,
  payload: { crewSortOption: option },
});

export const setMaintenanceSortOption = (option: string): SetMaintenanceSortOption => ({
  type: actions.SET_MAINTENANCE_SORT_OPTION,
  payload: { maintenanceSortOption: option },
});

export const setSelectedTimelines = (timelines: SelectedTimeline[]) => {
  return {
    type: actions.SET_SELECTED_TIMELINES,
    payload: { selectedTimelines: timelines },
  };
};

export const setIsAircraftScheduleUpdating = (isUpdating: boolean) => {
  return {
    type: actions.SET_IS_AIRCRAFT_SCHEDULE_UPDATING,
    payload: { isAircraftScheduleUpdating: isUpdating },
  };
};

export const setIsUpdatingAircraftContracts = (isUpdating: boolean) => {
  return {
    type: actions.SET_IS_UPDATING_AIRCRAFT_CONTRACTS,
    payload: { isUpdateAircraftContracts: isUpdating },
  };
};

export type LoadAircraftScheduleEntriesAction = {
  type: string;
  payload: { config: Config; aircraftIDs: string[] };
};

export const loadAircraftScheduleEntries = (
  config: Config,
  aircraftIDs: string[],
): LoadAircraftScheduleEntriesAction => {
  return {
    type: actions.LOAD_AIRCRAFT_SCHEDULE_ENTRIES,
    payload: { config, aircraftIDs },
  };
};

export type LoadAircraftContractsAction = {
  type: string;
  payload: { config: Config };
};

export const loadAircraftContracts = (config: Config): LoadAircraftContractsAction => {
  return {
    type: actions.LOAD_AIRCRAFT_CONTRACTS,
    payload: { config },
  };
};

export const checkOverlapsAction = (
  entryType: EntryType,
  entryID: string,
  from: moment.Moment,
  to: moment.Moment,
  userID: string,
): CheckOverlapsAction => {
  return {
    type: actions.CHECK_OVERLAPS,
    payload: { entryType, entryID, from, to, userID },
  };
};

export const resetCheckOverlapsAction = () => {
  return {
    type: actions.RESET_CHECK_OVERLAPS,
  };
};

export const aircraftLogsFromFlightsAction = (
  startTime: moment.Moment,
  endTime: moment.Moment,
  userID: string,
): AircraftLogsFromFlightsAction => {
  return {
    type: actions.GET_LOGS_FROM_FLIGHTS,
    payload: { startTime, endTime, userID },
  };
};

export const resetLogsFromFlightsAction = () => {
  return {
    type: actions.RESET_LOGS_FROM_FLIGHTS,
  };
};

export type OnVisibleTimelinePeriodChangeAction = {
  type: string;
  payload: { from: number; to: number };
};

export const onVisibleTimelinePeriodChange = (
  from: number,
  to: number,
): OnVisibleTimelinePeriodChangeAction => {
  return {
    type: actions.ON_VISIBLE_TIMELINE_PERIOD_CHANGE,
    payload: { from, to },
  };
};

export const triggerRealTimeUpdateMsg = () => {
  return {
    type: actions.TRIGGER_REAL_TIME_UPDATE_MGS,
  };
};
