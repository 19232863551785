import { Moment } from 'moment-timezone';

function isFlightTimeWithinRestTime(
  timeOff: Moment | null,
  timeOn: Moment | null,
  restStart: Moment | null,
  restEnd: Moment | null,
): boolean {
  if (!timeOff || !timeOn || !restStart || !restEnd) {
    return false;
  }
  return !(
    (timeOff.isBefore(restStart) && timeOn.isBefore(restStart)) ||
    (timeOff.isAfter(restEnd) && timeOn.isAfter(restEnd))
  );
}

export default isFlightTimeWithinRestTime;
