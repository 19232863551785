import { all, call, put, select, take, takeEvery, takeLeading } from 'redux-saga/effects';
import actions, {
  editFields,
  EditValueAction,
  FlightTimesEditValueAction,
  loadMore,
  resetAircraftEntryDetailsState,
  resetEntryDetailsState,
  runValidation,
  setCompanyAircraft,
  setCompanyClients,
  setCompanyRegulations,
  setCompanyTags,
  setEntry,
  SetEntryPeriodAction,
  SetIfShowDetailsAction,
  setIsEditing,
  SetIsEditingAction,
  SetIsShowAircraftDetailsAction,
  setIsShowDetails,
  setMoreFutureEntries,
  setMorePastEntries,
  setMostRecentDutyEntry,
  setPilots,
  setPreferredVersion,
  setSelectedAircraft,
  setSelectedAircraftId,
  setSelectedPilot,
  setSelectedPilotId,
  SetSelectedPilotIdAction,
  setState,
} from './actions';
import moment from 'moment-timezone';

import { loadPilotsWorker } from 'redux/pages/crew-scheduling-v3/loadPilots';
import api from '../../../../../services/api';
import { getCompanyID } from '../../../../../redux/selectors';
import userActions from '../../../../../redux/user/actions';
import { actionTypes as commonActionTypes } from '../../../../../redux/common/actions';
import { PreferredVersion } from '../../../../../common/types/preferredVersoon';
import LocalCache from '../../../../local-storage/local-storage';
import { saveEntryWorker, watchAddEntry, watchPatchEntry } from './workers/saveEntry';
import { runValidationWorker } from './workers/validation';
import { hideMobileMenu } from '../../../../../redux/settings/actions';
import { buildEntry } from './helpers/entryHelper';
import { runFieldsValidationWorker } from './workers/fieldsValidations';
import { runFieldsCalculationWorker } from './workers/fieldsCalculations';
import { getCommon, getCSMobile } from './selectors';
import { initGuarder } from './workers/guard';
import { watchOnAddNewAircraftType } from './workers/aircraftTypeAdd';
import { watchOnDeleteAircraftType } from './workers/aircraftTypeDelete';
import { deleteEntryWorker, watchDeleteEntry } from './workers/deleteEntry';
import { apiEntryTypeMap } from '../../../../../common/types/apiEntryTypes';
import { FeatureFlag } from '../../../../../utils/feature-flags/FeatureFlagsProvider';
import {
  fetchEntries,
  fetchEntriesForPeriodWorker,
  fetchRelatedPilotsWorker,
} from './workers/fetchEntries';
import { watchOnRestoreAircraftInformation } from './workers/aircraftInformationRestore';
import { selectUserRoleSaga } from '../../../../../common/saga/selectors';
import { fetchAircraftEntries } from './workers/fetchAircraftEntries';

export function* loadPilots() {
  try {
    const { sortedPilots, crewCertificationsData, crewMemberBasesData } = yield loadPilotsWorker();

    yield put(setPilots(sortedPilots));

    yield put({
      type: actions.SET_STATE,
      payload: {
        isPilotsLoaded: true,
        crewCertifications: crewCertificationsData,
        crewMemberBases: crewMemberBasesData,
      },
    });
  } catch (error) {
    console.log(error);
  }
}

export function* loadAircraft() {
  try {
    const result = yield call(fetchCompanyAircraftWorker);
    if (result && result.sortedAircraft) {
      yield put(setCompanyAircraft(result.sortedAircraft));
    }
    yield put({
      type: actions.SET_STATE,
      payload: {
        isAircraftLoaded: true,
      },
    });
  } catch (error) {
    console.log(error);
  }
}

function* fetchCompanyScheduleVersionWorker() {
  const companyID = yield select(({ user: { Company } }) => Company.ID);
  const {
    data: { Data },
  } = yield call(api.get, `/v1/companies/${companyID}/schedule-version`);
  yield put({
    type: actions.SET_COMPANY_SCHEDULE_VERSION,
    payload: Data,
  });

  const { publishedVersions, companyScheduleVersion } = yield select(getCSMobile);
  const { CompanyUsers } = yield select(({ user }) => user);
  const companyPreferredVersion: PreferredVersion = CompanyUsers[0]?.PreferredVersion;

  const preferredVersion = companyPreferredVersion;
  let selectedVersion = preferredVersion;
  if (preferredVersion === 'current') {
    selectedVersion = companyScheduleVersion.CurrentVersion;
  }

  const selectedPublishedVersion = publishedVersions?.find(
    pv => pv.Version === parseInt(selectedVersion as string, 10),
  );

  if (selectedPublishedVersion) {
    yield put(setPreferredVersion(selectedPublishedVersion.Name));
  }
}

function* fetchCompanyAircraftWorker() {
  const companyID = yield select(({ user: { Company } }) => Company.ID);
  const {
    data: { Data },
  } = yield call(api.get, `/v1/companies/${companyID}/aircraft`);

  yield put({
    type: actions.SET_COMPANY_AIRCRAFTS,
    payload: Data,
  });
}

function* loadPublishedVersionWorker() {
  const companyID = yield select(({ user: { Company } }) => Company.ID);
  const {
    data: { Data },
  } = yield call(api.get, `/v1/companies/${companyID}/publish/list`);
  yield put({
    type: actions.SET_PUBLISHED_VERSIONS,
    payload: Data,
  });

  yield call(fetchCompanyScheduleVersionWorker);
}

function* loadCompanyClientsWorker() {
  const companyID = yield select(({ user: { Company } }) => Company.ID);
  const {
    data: { Data },
  } = yield call(api.get, `/v1/companies/${companyID}/clients`);
  yield put(setCompanyClients(Data));
}

function* loadCompanyTagsWorker() {
  const companyID = yield select(({ user: { Company } }) => Company.ID);
  const {
    data: { Data },
  } = yield call(api.get, `/v1/companies/${companyID}/company-tag`);
  yield put(setCompanyTags(Data));
}

export function* loadMostRecentDutyEntry(userID: string) {
  const companyID = yield select(({ user: { Company } }) => Company.ID);
  const { entryType } = yield select(getCSMobile);

  if (entryType === apiEntryTypeMap.DutyTimeEntries) {
    if (!userID) {
      yield put(setMostRecentDutyEntry(null));
      return;
    }

    const mostRecentEntryResponse = yield api.get(`/v1/companies/${companyID}/duty-times`, {
      params: {
        user_id: userID,
        order: 'start_time desc',
        limit: 1,
      },
    });
    const mostRecentEntry = mostRecentEntryResponse?.data?.Data?.[0] || null;
    if (mostRecentEntry) {
      yield put(setMostRecentDutyEntry(mostRecentEntry));
    }
  }
}

function* loadCompanyRegulationsWorker() {
  const companyID = yield select(({ user: { Company } }) => Company.ID);
  const userRoles = yield* selectUserRoleSaga();

  try {
    const {
      data: { Data },
    } = yield call(api.get, `v1/companies/${companyID}/company-regulations/list`, {
      params: { active: true },
    });

    yield put(setCompanyRegulations(Data));
  } catch (err) {
    console.log(err);
  } finally {
  }
}

function* setCachedState() {
  const user = yield select(({ user }) => user);
  const { entryType } = yield select(getCSMobile);
  const localCache = new LocalCache('crewSchedulingMobile', user);
  const selectedEntryType = localCache.getCached('selectedEntryType', null);
  if (selectedEntryType && entryType !== selectedEntryType) {
    yield put(setState({ entryType: selectedEntryType }));
  }
}

function* setVisibilityWorker() {
  const user = yield select(({ user }) => user);
  const { id, CompanyUsers } = user;
  const companyUser = CompanyUsers.find(({ UserID }) => UserID === id);
  const companySettings = yield select(({ user: { Company } }) => Company.Settings);

  let isAllowToViewAllPilots = false;
  let isAllowedToViewAircraftSchedule = false;
  const isAdmin = companyUser.Roles.includes('admin');
  const isPilot = companyUser.Roles.includes('pilot');
  const isViewOnly = companyUser.Roles.includes('viewOnly');
  const isEngineer = companyUser.Roles.includes('viewOnly');
  const userRoles = companyUser.Roles;

  if (isAdmin || (isPilot && companySettings.PilotCanViewAllPilotsCrewSchedule)) {
    isAllowToViewAllPilots = true;
  } else if (isPilot || isViewOnly) {
    isAllowToViewAllPilots = false;
  }

  if (
    companySettings?.PilotCanViewAircraftSchedule === true &&
    (isPilot ||
      isAdmin ||
      isEngineer ||
      isViewOnly ||
      ['subContractor', 'taggedScheduleViewer'].some(role => userRoles?.includes(role)))
  ) {
    isAllowedToViewAircraftSchedule = true;
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isAllowToViewAllPilots,
    },
  });

  yield put({
    type: actions.SET_STATE,
    payload: {
      isAllowedToViewAircraftSchedule,
    },
  });
}

function* selectDefaultPilotWorker() {
  const user = yield select(({ user }) => user);
  const { isAllowToViewAllPilots } = yield select(getCSMobile);

  const { id, name, CompanyUsers } = user;

  const companyUser = CompanyUsers.find(({ UserID }) => UserID === id);

  if (!isAllowToViewAllPilots && companyUser.Roles.includes('pilot')) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        selectedPilotId: id,
      },
    });
    yield put(setSelectedPilotId(id));
    yield put({
      type: actions.SET_STATE,
      payload: {
        selectedPilotName: name,
      },
    });
  } else {
    const localCache = new LocalCache('crewSchedulingMobile', user);
    const selectedCrewMemberId = localCache.getCached('selectedCrewMemberId', null);
    const selectedCrewMemberName = localCache.getCached('selectedCrewMemberName', null);

    yield put({
      type: actions.SET_STATE,
      payload: {
        selectedPilotName: selectedCrewMemberName,
      },
    });
    yield put(setSelectedPilotId(selectedCrewMemberId));
  }
}

function* selectDefaultAircraftWorker() {
  const user = yield select(({ user }) => user);
  const companySettings = yield select(({ user: { Company } }) => Company.Settings);
  const { isAllowedToViewAircraftSchedule } = yield select(getCSMobile);
  if (isAllowedToViewAircraftSchedule) {
    const localCache = new LocalCache('crewSchedulingMobile', user);
    const selectedAircraftId = localCache.getCached('selectedAircraftId', null);
    const selectedAircraftTailNumber = localCache.getCached('selectedAircraftTailNumber', null);
    const selectedAircraftCommonDesignator = localCache.getCached(
      'selectedAircraftCommonDesignator',
      null,
    );
    yield put({
      type: actions.SET_STATE,
      payload: {
        selectedAircraftTailNumber: selectedAircraftTailNumber,
        selectedAircraftCommonDesignator: selectedAircraftCommonDesignator,
      },
    });
    yield put(setSelectedAircraftId(selectedAircraftId));
  }
}

// we need this worker to know when pilot id set from cache or changed
function* watchSelectedPilotIdSet(action: SetSelectedPilotIdAction) {
  const { selectedPilotId } = action.payload;
  yield call(loadMostRecentDutyEntry, selectedPilotId);
}

function* watchSelectedPilotChange(action: ReturnType<typeof setSelectedPilot>) {
  const { selectedPilotId, selectedPilotName } = action.payload;
  yield put(setSelectedPilotId(selectedPilotId));

  if (selectedPilotId !== null) {
    const user = yield select(({ user }) => user);
    const localCache = new LocalCache('crewSchedulingMobile', user);
    const { entryPeriod, entryType } = yield select(getCSMobile);
    let [from, to] = entryPeriod;
    const fetchEntriesRequest = call(fetchEntries, entryType, from, to, true);
    yield localCache.setCached('selectedCrewMemberId', selectedPilotId);
    yield localCache.setCached('selectedCrewMemberName', selectedPilotName);

    yield all([fetchEntriesRequest, yield call(checkBeforeAfterEntries, from, to, false)]);
    yield call(loadMostRecentDutyEntry, selectedPilotId);
  }
}

function* watchSelectedAircraftChange(action: ReturnType<typeof setSelectedAircraft>) {
  const {
    selectedAircraftId,
    selectedAircraftTailNumber,
    selectedAircraftCommonDesignator,
  } = action.payload;
  yield put(setSelectedAircraftId(selectedAircraftId));

  if (selectedAircraftId !== null) {
    const user = yield select(({ user }) => user);
    const localCache = new LocalCache('crewSchedulingMobile', user);
    const { entryPeriod, entryType } = yield select(getCSMobile);
    let [from, to] = entryPeriod;
    yield call(fetchAircraftEntries, entryType, from, to, true);
    yield localCache.setCached('selectedAircraftId', selectedAircraftId);
    yield localCache.setCached('selectedAircraftTailNumber', selectedAircraftTailNumber);
    yield localCache.setCached(
      'selectedAircraftCommonDesignator',
      selectedAircraftCommonDesignator,
    );
  }
}

function* watchEntryTypeChange(action) {
  const { entryPeriod, selectedPilotId, selectedAircraftId } = yield select(getCSMobile);
  const { entryType } = action.payload;

  const user = yield select(({ user }) => user);
  const localCache = new LocalCache('crewSchedulingMobile', user);
  yield localCache.setCached('selectedEntryType', entryType);

  if (
    (!selectedPilotId && entryType !== apiEntryTypeMap.AircraftScheduleEntries) ||
    (!selectedAircraftId && entryType === apiEntryTypeMap.AircraftScheduleEntries)
  ) {
    return;
  }

  let [from, to] = entryPeriod;
  let fetchEntriesRequest;

  if (entryType === apiEntryTypeMap.AircraftScheduleEntries) {
    fetchEntriesRequest = call(fetchAircraftEntries, entryType, from, to, false);
  } else {
    fetchEntriesRequest = call(fetchEntries, entryType, from, to, false);
  }

  yield all([fetchEntriesRequest, yield call(checkBeforeAfterEntries, from, to, false)]);
  yield call(loadMostRecentDutyEntry, selectedPilotId);
}

function* watchComponentLoaded() {
  const { isCompanyLoaded, isUserLoaded } = yield select(({ user }) => user);
  const { isFeatureFlagsLoaded } = yield select(getCommon);

  const awaitActions = [];
  if (!isCompanyLoaded) {
    awaitActions.push(take(userActions.SET_COMPANY));
  } else if (!isUserLoaded) {
    awaitActions.push(take(userActions.SET_USER));
  }

  // await for company or user to be loaded
  yield all(awaitActions);
  if (!isFeatureFlagsLoaded) {
    yield take(commonActionTypes.SET_FEATURE_FLAGS);
  }

  yield call(initGuarder);
  yield call(setCachedState);
  yield call(setVisibilityWorker);
  yield call(selectDefaultPilotWorker);
  yield call(selectDefaultAircraftWorker);

  yield all([
    call(loadPublishedVersionWorker),
    call(loadPilots),
    call(loadAircraft),
    call(loadCompanyRegulationsWorker),
    call(loadCompanyClientsWorker),
    call(loadCompanyTagsWorker),
  ]);

  const { entryType } = yield select(getCSMobile);

  // set the range to load the entries
  let from = moment().subtract(30, 'days');
  let to = moment().add(30, 'days');

  const { fetchedStart, fetchedEnd } = yield call(
    fetchEntriesForPeriodWorker,
    from,
    to,
    entryType,
    true,
  );

  if (fetchedStart && moment(fetchedStart).isBefore(from)) {
    from = moment(fetchedStart).subtract(30, 'days');
  }

  if (fetchedEnd && moment(fetchedEnd).isAfter(to)) {
    to = moment(fetchedEnd).add(30, 'days');
  }

  let fetchEntriesRequest;
  if (entryType === apiEntryTypeMap.AircraftScheduleEntries) {
    fetchEntriesRequest = call(fetchAircraftEntries, entryType, from, to, true);
  } else {
    fetchEntriesRequest = call(fetchEntries, entryType, from, to, true);
  }

  yield all([fetchEntriesRequest, yield call(checkBeforeAfterEntries, from, to, true)]);
}

function* checkBeforeAfterEntries(from: moment.Moment, to: moment.Moment, isInitialLoad: boolean) {
  const { entryType, morePastEntries, moreFutureEntries } = yield select(getCSMobile);
  const { fetchedEntriesBefore, fetchedEntriesAfter } = yield call(
    fetchEntriesForPeriodWorker,
    from,
    to,
    entryType,
    false,
  );
  if (!fetchedEntriesAfter) {
    yield put(setMoreFutureEntries(false));
  } else {
    yield put(setMoreFutureEntries(true));
  }
  if (!fetchedEntriesBefore) {
    yield put(setMorePastEntries(false));
  } else {
    yield put(setMorePastEntries(true));
  }
}

function* watchLoadMore(action: ReturnType<typeof loadMore>) {
  let { entryPeriod, entryType } = yield select(getCSMobile);
  let [from, to] = entryPeriod;

  const isPast = action.payload;

  if (isPast) {
    yield put(
      setState({
        isLoadingPast: true,
      }),
    );
    from = moment(from).subtract(30, 'days');
  } else {
    // todo: check visible end
    to = moment(to).add(30, 'days');
    yield put(
      setState({
        isLoadingFuture: true,
      }),
    );
  }

  yield put(
    setState({
      entryPeriod: [from, to],
    }),
  );

  let fetchEntriesRequest;
  if (entryType === apiEntryTypeMap.AircraftScheduleEntries) {
    fetchEntriesRequest = call(fetchAircraftEntries, entryType, from, to, false);
  } else {
    fetchEntriesRequest = call(fetchEntries, entryType, from, to, false);
  }

  yield all([fetchEntriesRequest, yield call(checkBeforeAfterEntries, from, to, false)]);

  yield put(
    setState({
      isLoadingPast: false,
      isLoadingFuture: false,
    }),
  );
}

function* watchFlightInfoEditableFieldsChange(action: FlightTimesEditValueAction) {
  const { featureFlags } = yield select(getCommon);
  const { key, isFormValid } = action.payload;

  // run UI validation
  yield call(runFieldsValidationWorker, key);

  // run calculation
  yield call(runFieldsCalculationWorker, key);

  if (featureFlags[FeatureFlag.DetailedFlightTimes]) {
    if (isFormValid) {
      yield call(runValidationAfterFieldChange, key);
    }
  } else if (!featureFlags[FeatureFlag.DetailedFlightTimes]) {
    yield call(runValidationAfterFieldChange, key);
  }
}

function* watchEditableFieldsChange(action: EditValueAction) {
  const { key } = action.payload;

  // run UI validation
  yield call(runFieldsValidationWorker, key);

  // run calculation
  yield call(runFieldsCalculationWorker, key);

  yield call(runValidationAfterFieldChange, key);
}

function* runValidationAfterFieldChange(editedField: string) {
  let { editValues, entry, entryType, validations, isDetailedFlightTimesFormValid } = yield select(
    getCSMobile,
  );

  if (entryType === apiEntryTypeMap.AircraftScheduleEntries) {
    return;
  }

  const subParts =
    editValues[editFields.SubParts] !== undefined
      ? editValues[editFields.SubParts]
      : entry.Subparts ||
        entry.FlightInformation?.SubParts ||
        (entry.AircraftInformation?.FlightInformation?.length > 0 &&
          entry.AircraftInformation?.FlightInformation[0]?.Subparts) ||
        [];

  const isFieldsValidationIssues =
    (validations && Object.keys(validations).length > 0) || !isDetailedFlightTimesFormValid;

  let isRunValidation =
    subParts.length !== 0 &&
    ![
      editFields.Notes,
      editFields.OverrideNotes,
      editFields.AssignedClient,
      editFields.AssignedAircraft,
      editFields.Tag,
      editFields.CustomSections,
    ].includes(editedField) &&
    !isFieldsValidationIssues;

  if (entryType === apiEntryTypeMap.CrewScheduleEntries) {
    const scheduleEntryTypeID =
      editValues[editFields.CompanyScheduleTypeID] !== undefined
        ? editValues[editFields.CompanyScheduleTypeID]
        : entry.CompanyScheduleType?.ID;
    if (!scheduleEntryTypeID) {
      isRunValidation = false;
    }
  }

  if (isRunValidation) {
    yield put(runValidation(entry, entryType));
  }
}

function* watchOnCreateNewEntry() {
  const { selectedPilotId, pilots, defaultStartAirportID, defaultEndAirportID } = yield select(
    getCSMobile,
  );
  const { defaultScheduleType } = yield select(getCommon);
  const companyID = yield select(getCompanyID);
  const companySettings = yield select(({ user: { Company } }) => Company.Settings);

  const pilotProfile = pilots.find(p => p.ID === selectedPilotId)?.Profile;
  const defaultCertification = pilotProfile.PilotAircraftCertifications?.[0];
  const defaultAircraftType = defaultCertification?.AircraftType;
  yield put(
    setEntry(
      buildEntry(
        selectedPilotId,
        companyID,
        companySettings,
        pilotProfile,
        defaultAircraftType,
        defaultStartAirportID,
        defaultEndAirportID,
        defaultScheduleType,
      ),
    ),
  );
  yield put(hideMobileMenu());
  yield put(setIsShowDetails(true));
  yield put(setIsEditing(true));
  yield put(setState({ isNewEntry: true }));
}

function* watchEntryDetailsVisibilityChange(action: SetIfShowDetailsAction) {
  const { isShowDetails } = action.payload;

  if (!isShowDetails) {
    yield put(resetEntryDetailsState());
  }
}

function* watchAircraftEntryDetailsVisibilityChange(action: SetIsShowAircraftDetailsAction) {
  const { isShowAircraftDetails } = action.payload;

  if (!isShowAircraftDetails) {
    yield put(resetAircraftEntryDetailsState());
  }
}

function* watchEditingModeChange(action: SetIsEditingAction) {
  const { isEditing } = action.payload;
  const { entry } = yield select(getCSMobile);
  const entryID = entry?.ID;

  if (entryID && !isEditing) {
    yield put(resetEntryDetailsState());
    yield put(setEntry(entry));
  }
}

function* watchAddNewAircraftType() {
  yield call(runFieldsValidationWorker, editFields.AircraftType);
}

function* watchRemoveAircraftType() {
  yield call(runFieldsValidationWorker, editFields.AircraftType);
}

function* handleEntryPeriodChange(action: SetEntryPeriodAction) {
  const entryPeriod = action.payload.entryPeriod;
  const entryType = action.payload.entryType;
  const [from, to] = entryPeriod;
  yield call(checkBeforeAfterEntries, from, to, false);
  if (entryType !== apiEntryTypeMap.AircraftScheduleEntries) {
    yield call(fetchEntries, entryType, from, to, false);
  } else if (entryType === apiEntryTypeMap.AircraftScheduleEntries) {
    yield call(fetchAircraftEntries, entryType, from, to, false);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_MORE, watchLoadMore),
    takeEvery(actions.SET_ENTRY_TYPE, watchEntryTypeChange),
    takeEvery(actions.SET_SELECTED_PILOT, watchSelectedPilotChange),
    takeEvery(actions.SET_SELECTED_AIRCRAFT, watchSelectedAircraftChange),
    takeEvery(actions.SET_ENTRY_PERIOD, handleEntryPeriodChange),
    takeLeading(actions.COMPONENT_LOADED, watchComponentLoaded),
    takeEvery(actions.SAVE_ENTRY, saveEntryWorker),
    takeEvery(actions.RUN_VALIDATION, runValidationWorker),
    takeEvery(actions.SET_EDIT_VALUE, watchEditableFieldsChange),
    takeEvery(actions.CLEAR_EDIT_VALUE, watchEditableFieldsChange),
    takeEvery(actions.SET_FLIGHT_INFO_EDIT_VALUE, watchFlightInfoEditableFieldsChange),
    takeEvery(actions.CREATE_NEW_ENTRY, watchOnCreateNewEntry),
    takeEvery(actions.ADD_NEW_AIRCRAFT_TYPE, watchOnAddNewAircraftType),
    takeEvery(actions.DELETE_AIRCRAFT_TYPE, watchOnDeleteAircraftType),
    takeEvery(actions.RESTORE_ORIGINAL_FLIGHT_INFORMATION, watchOnRestoreAircraftInformation),
    takeEvery(actions.DELETE_ENTRY, deleteEntryWorker),
    takeEvery(actions.AFTER_ENTRY_DELETED, watchDeleteEntry),
    takeEvery(actions.AFTER_ENTRY_CREATED, watchAddEntry),
    takeEvery(actions.AFTER_ENTRY_PATCHED, watchPatchEntry),
    takeEvery(actions.SET_IS_SHOW_DETAILS, watchEntryDetailsVisibilityChange),
    takeEvery(actions.SET_IS_SHOW_AIRCRAFT_DETAILS, watchAircraftEntryDetailsVisibilityChange),
    takeEvery(actions.SET_IS_EDITING, watchEditingModeChange),
    takeEvery(actions.SET_SELECTED_PILOT_ID, watchSelectedPilotIdSet),
    takeEvery(actions.ADD_NEW_AIRCRAFT_TYPE, watchAddNewAircraftType),
    takeEvery(actions.DELETE_AIRCRAFT_TYPE, watchRemoveAircraftType),
    takeEvery(actions.FETCH_RELATED_PILOTS, fetchRelatedPilotsWorker),
  ]);
}
