import moment from 'moment-timezone';

export const composeKey = (...keys: string[]) => {
  return keys.join('_');
};

export const DIVIDER_TYPE = 'month-divider';

export function populateMonthDividers(entries: { StartTime: Date }[]) {
  const result = [];
  let prevMonth: moment.Moment | null = null;

  for (const entry of entries) {
    const entryMonth = moment(entry.StartTime).startOf('month');

    if (!prevMonth || !entryMonth.isSame(prevMonth, 'month')) {
      const formattedMonth = entryMonth.format('MMMM YYYY');
      result.push({
        type: DIVIDER_TYPE,
        month: formattedMonth,
      });
      prevMonth = entryMonth;
    }

    result.push(entry);
  }

  return result;
}

export function removeDuplicateDividers(resultData: Record<string, any[]>): Record<string, any[]> {
  const cleanedData: Record<string, any[]> = {};

  for (const key in resultData) {
    const entries = resultData[key];
    const seenMonths = new Set<string>();
    const deduplicated = [];

    for (const entry of entries) {
      if (entry.type === DIVIDER_TYPE) {
        if (!seenMonths.has(entry.month)) {
          seenMonths.add(entry.month);
          deduplicated.push(entry);
        }
      } else {
        deduplicated.push(entry);
      }
    }

    cleanedData[key] = deduplicated;
  }

  return cleanedData;
}
