import React from 'react';
import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import { getCompany, getUser } from '../../../redux/selectors';
import { getShowTimezoneWarningBanner } from '../../../pages/data/organization/crew-scheduling-v3/redux-saga/selectors';
import styles from './style.module.scss';
import { ENVIRONMENT } from '../../../config';

const currentYear = new Date().getFullYear();
const copyrightYears = `© 2017 - ${currentYear}`;

const Footer = () => {
  const currentUser = useSelector(getUser);
  const isShowTimezoneWarningBanner = useSelector(getShowTimezoneWarningBanner);
  const { Settings: companySettings } = useSelector(getCompany);
  const isTimezoneBannerVisible =
    isShowTimezoneWarningBanner &&
    companySettings?.TimezoneWarningBanner &&
    companySettings?.CompanyDefaultTimezone &&
    currentUser?.Profile?.Metadata?.SelectedTimeZone !== companySettings?.CompanyDefaultTimezone

  return (
    <div
      className={styles.footer}
      style={{ padding: `1.07rem 2.3rem ${isTimezoneBannerVisible ? '4rem' : '2.3rem'}` }}
    >
      <Row>
        <Col span={24}>
          <div className={styles.copyright}>
            <img src="resources/images/airtrail.png" alt="Underdog Software" />
            <span style={{ marginTop: '9px' }}>
              {`Environment: ${ENVIRONMENT}`}
              {copyrightYears}{' '}
              <a href="http://airtrail.ca/" target="_blank" rel="noopener noreferrer">
                Air Trail
              </a>
              All rights reserved
            </span>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Footer;
