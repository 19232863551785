import { put } from 'redux-saga/effects';
import LocalCache from '../../../../../../local-storage/local-storage';
import { scheduleActions } from '../../actions';
import { selectUserSaga } from '../../../../../../../common/saga/selectors';

export function* setMaintenanceSortOptionFromCache() {
  const user = yield* selectUserSaga();
  const localCache = new LocalCache('crewSchedulingV2', user);
  const maintenanceSortCache = localCache.getCached('maintenanceSortOption');
  if (maintenanceSortCache) {
    yield put(scheduleActions.setMaintenanceSortOption(maintenanceSortCache));
  } else {
    yield put(scheduleActions.setMaintenanceSortOption('firstNameAlphabeticallyAsc'));
  }
}
