import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, withRouter } from 'react-router-dom';
import { Menu, Layout, Button, Row, Col, Avatar, Typography, Popover } from 'antd';
import Icon, { CaretDownOutlined, DownOutlined, LogoutOutlined } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import store from 'store';
import { Scrollbars } from 'react-custom-scrollbars';
import find from 'lodash.find';
import TimezoneSetting from 'pages/settings/timezone/timezoneSetting';
import TZTagUTC from 'pages/settings/timezone/TZTagUtc';
import Guard from 'pages/permissions/crew-schedule-v2-guard';
import { getUser, getSettings, getMenu, getCommon } from 'redux/selectors';
import { FeatureFlag } from 'utils/feature-flags/FeatureFlagsProvider';
import styles from './style.module.scss';

const { Sider } = Layout;
const { SubMenu, Divider } = Menu;

const MenuLeft = ({ closeOnSelect }) => {
  const user = useSelector(getUser);
  const { isMobileView, isMenuCollapsed, isSettingsOpen } = useSelector(getSettings);
  const { featureFlags } = useSelector(getCommon);
  const { menuLeftData: menuData } = useSelector(getMenu);
  const location = useLocation();
  const [selectedKeys, setSelectedKeys] = useState(store.get('app.menu.selectedKeys') || []);
  const [openedKeys, setOpenedKeys] = useState(store.get('app.menu.openedKeys') || []);
  const dispatch = useDispatch();
  const guard = new Guard('pages');

  useEffect(() => {
    if (isMenuCollapsed && !isMobileView) {
      setOpenedKeys([]);
    }

    parseSelectedKeys();
  }, [isMobileView, isMenuCollapsed, isSettingsOpen]);

  const parseSelectedKeys = () => {
    const flattenItems = (items, key) =>
      items.reduce((flattenedItems, item) => {
        flattenedItems.push(item);
        if (Array.isArray(item[key])) {
          return flattenedItems.concat(flattenItems(item[key], key));
        }
        return flattenedItems;
      }, []);
    const selectedItem = find(flattenItems(menuData, 'children'), ['url', location.pathname]);

    setSelectedKeys(selectedItem ? [selectedItem.key] : []);
  };

  const onCollapse = (value, type) => {
    if (type === 'responsive' && isMenuCollapsed) {
      return;
    }

    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMenuCollapsed',
        value: !isMenuCollapsed,
      },
    });

    setOpenedKeys([]);
  };

  const onOpenChange = keys => {
    store.set('app.menu.openedKeys', keys);
    setOpenedKeys(keys);
  };

  const handleClick = e => {
    store.set('app.menu.selectedKeys', [e.key]);
    // custom action on settings menu item
    if (e.key === 'settings') {
      dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: 'isSettingsOpen',
          value: !isSettingsOpen,
        },
      });
      return;
    }
    if (closeOnSelect) {
      closeOnSelect();
    }

    setSelectedKeys([e.key]);
  };

  const getIcon = menuItem => {
    if (!menuItem) return null;
    const { iconName, iconComponent } = menuItem;
    if (iconComponent) {
      return <Icon component={iconComponent} className={`${styles.icon} icon-collapsed-hidden`} />;
    }
    if (iconName) {
      return (
        <Icon
          style={{
            marginRight: '8px',
            lineHeight: 'inherit',
            float: 'right',
          }}
          type={iconName}
          theme="filled"
        />
      );
    }
    return null;
  };

  const generateMenuItems = () => {
    const generateItem = item => {
      const { key, title, url, disabled } = item;
      const icon = item ? getIcon(item) : null;

      if (item.divider) {
        return <Divider key={Math.random()} />;
      }
      if (item.url) {
        return (
          <Menu.Item key={key} disabled={disabled}>
            {item.target ? (
              <a href={url} target={item.target} rel="noopener noreferrer">
                {icon}
                <span className={styles.title}>{title}</span>
              </a>
            ) : (
              <Link to={url} className={styles.menuChildren}>
                {icon}
                <span className={styles.title}>{title}</span>
              </Link>
            )}
          </Menu.Item>
        );
      }
      return (
        <Menu.Item key={key} disabled={disabled}>
          {icon}
          <span className={styles.title}>{title}</span>
        </Menu.Item>
      );
    };

    const generateSubmenu = items => {
      return items
        .filter(menuItem => {
          if (
            menuItem.key === 'crew-scheduling-mobile' &&
            !featureFlags[FeatureFlag.CrewScheduleMobile]
          ) {
            return false;
          }
          if (menuItem.key === 'flightWatch' && !featureFlags[FeatureFlag.FlightWatch]) {
            return false;
          }
          return guard.canAccess(`${menuItem.key}`);
        })
        .map(menuItem => {
          if (menuItem.children) {
            const subMenuTitle = (
              <span key={menuItem.key}>
                <span className={styles.title}>{menuItem.title}</span>
                {getIcon(menuItem)}
              </span>
            );
            return (
              <SubMenu title={subMenuTitle} key={menuItem.key}>
                {generateSubmenu(menuItem.children)}
              </SubMenu>
            );
          }
          return generateItem(menuItem);
        });
    };

    return menuData.map(menuItem => {
      if (menuItem.children) {
        const subMenuTitle = (
          <span key={menuItem.key}>
            <span className={styles.title}>{menuItem.title}</span>
            {getIcon(menuItem)}
          </span>
        );
        return (
          <SubMenu title={subMenuTitle} key={menuItem.key}>
            {generateSubmenu(menuItem.children)}
          </SubMenu>
        );
      }
      return generateItem(menuItem);
    });
  };

  const menuSettings = isMobileView
    ? {
        width: 256,
        collapsible: false,
        collapsed: false,
        onCollapse,
      }
    : {
        width: 256,
        collapsible: true,
        collapsed: isMenuCollapsed,
        onCollapse,
        breakpoint: 'lg',
      };
  const menu = generateMenuItems();

  // handle logout
  const handleLogout = () => {
    dispatch({
      type: 'user/LOGOUT',
    });
  };

  const profilePopover = () => {
    return (
      <div>
        <Row>
          {/* <Col span={24}>
            <Avatar style={{backgroundColor:"whitesmoke", margin:"6px auto" , display:"block"}} size={100} src="https://joeschmoe.io/api/v1/male/random" />
          </Col> */}

          <Col span={24}>
            <p
              style={{ color: 'black', fontSize: '14px', marginBottom: '2px', textAlign: 'center' }}
            >
              {user.name}
            </p>
            <p style={{ color: 'gray', fontSize: '11px', textAlign: 'center' }}>{user.email}</p>
          </Col>
        </Row>
        <Row justify="center" style={{ width: '210px' }}>
          <Col span={24}>
            <TimezoneSetting
              config={{
                editable: true,
                labelOnly: false,
              }}
            />
          </Col>
        </Row>
        <Row>
          <Button
            danger
            type="primary"
            icon={<LogoutOutlined />}
            size="large"
            onClick={handleLogout}
            style={{ backgroundColor: 'none', margin: '10px auto', display: 'block', width: '93%' }}
          >
            Logout
          </Button>
        </Row>
      </div>
    );
  };

  const getIntials = companyName => {
    if (companyName && companyName.length > 0) {
      return companyName
        .split(' ')
        .map(word => word[0])
        .join('');
    }
    return '';
  };

  return (
    <Sider {...menuSettings} className={styles.menu}>
      <div className={styles.logo}>
        <Row style={{ padding: '7px 0px' }}>
          <Col>
            <img
              src="resources/images/airtrail.png"
              style={{ margin: '10px auto', height: '50%', display: 'block' }}
              alt=""
            />
            {/* {
                  user.Company?.Settings?.CompanyLogoUrl && user.Company?.Settings?.CompanyLogoUrl !== "" ?
                    <Avatar shape='square' style={{backgroundColor:"whitesmoke", margin:"0 auto" , display:"block"}} size={50} src={user.Company?.Settings?.CompanyLogoUrl} />
                  :
                    <Avatar shape='square' style={{backgroundColor:"#0e306b", margin:"0 auto" , display:"block"}} size={50}>
                      {getIntials(user.Company.Name)}
                    </Avatar>
                } */}
          </Col>
          {/* {
                !isMenuCollapsed &&
                <Col span={18}>
                  <div style={{marginTop:"10px"}}>

                    <p style={{color:"white" , fontSize:"14px" , marginBottom:"2px"}}>{user.Company?.Name}</p>
                    <p style={{color:"gray" , fontSize:"11px"}}>Powered by Airtrail</p>
                  </div>
                </Col>
              }
               */}
        </Row>
      </div>

      <Scrollbars
        className={isMobileView ? styles.scrollbarMobile : styles.scrollbarDesktop}
        renderThumbVertical={({ style, ...styleProps }) => (
          <div
            {...styleProps}
            style={{
              ...style,
              width: '4px',
              borderRadius: 'inherit',
              backgroundColor: '#c5cdd2',
              left: '1px',
            }}
          />
        )}
        autoHide={false}
      >
        <Menu
          theme="dark"
          onClick={handleClick}
          selectedKeys={selectedKeys}
          openKeys={openedKeys}
          onOpenChange={onOpenChange}
          mode="inline"
          className={styles.navigation}
        >
          {menu}
        </Menu>
      </Scrollbars>

      {(isMobile || user.Company?.Settings?.NewSidebar === true) && (
        <div style={{ padding: '10px' }}>
          <Popover
            content={profilePopover}
            title="Profile"
            trigger="click"
            overlayStyle={{ zIndex: 9999 }}
          >
            <Row
              style={{
                padding: '5px',
                borderRadius: '46px',
                paddingBottom: `${isMenuCollapsed ? '5px' : '0px'}`,
              }}
            >
              {isMenuCollapsed && (
                <Col span={isMenuCollapsed ? 24 : 6}>
                  <Avatar
                    style={{ backgroundColor: '#0e306b', margin: '0 auto', display: 'block' }}
                    size={50}
                  >
                    {getIntials(user.name)}
                  </Avatar>
                </Col>
              )}
              {!isMenuCollapsed && (
                <Col span={23}>
                  <p style={{ color: 'white', fontSize: '14px', marginBottom: '2px' }}>
                    {user.name}
                  </p>
                  <p style={{ color: 'gray', fontSize: '11px' }}>{user.email}</p>
                </Col>
              )}
              {!isMenuCollapsed && (
                <Col span={1}>
                  <CaretDownOutlined
                    style={{
                      color: 'white',
                      position: 'relative',
                      marginLeft: '-14px',
                      top: '23%',
                    }}
                  />
                </Col>
              )}
            </Row>

            <Row justify={isMenuCollapsed ? 'center' : 'left'} style={{ marginTop: '14px' }}>
              <TZTagUTC isMenuCollapsed={isMenuCollapsed} />
            </Row>
          </Popover>
        </div>
      )}
    </Sider>
  );
};

export default MenuLeft;
