export enum CrewScheduleFilter {
  CREW_MEMBER = 'crewMember',
  MAINTENANCE_MEMBER = 'maintenanceMember',
  CREW_CERTIFICATION = 'crewCertification',
  MAINTENANCE_CERTIFICATION = 'maintenanceCertification',
  CREW_MEMBER_BASE = 'crewMemberBase',
  RESOURCE = 'aircraftTail',
  RESOURCE_TYPE = 'aircraftType',
  RESOURCE_BASE = 'aircraftBase',
  DISPLAY_OPTIONS = 'displayOption',
  CLIENT = 'companyClient',
  CLIENT_CONTRACT = 'clientContract',
  TAG = 'companyTag',
}
