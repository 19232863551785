import { SetMaintenanceSortOption, setState } from '../actions';
import { put, select } from 'redux-saga/effects';
import { getUserRole } from '../../../selectors';
import { sortMaintenance } from '../loadMaintenance';

export function* watchMaintenanceSortOptionChanged(action: SetMaintenanceSortOption) {
  const { maintenanceSortOption } = action.payload;
  const { selectedMaintenance, maintenance, selectedMaintenanceMemberIds } = yield select(
    ({ pages: { crewSchedulingV2 } }) => crewSchedulingV2,
  );
  const userRoles = yield select(getUserRole) || [];
  const companySettings = yield select(({ user: { Company } }) => Company.Settings);
  const user = yield select(({ user }) => user);

  yield put(setState({ maintenanceSortOption: maintenanceSortOption }));
  if (!selectedMaintenanceMemberIds.length) {
    let sortedMaintenance = sortMaintenance(
      maintenance,
      maintenanceSortOption,
      user,
      userRoles,
      companySettings,
    );
    yield put(setState({ selectedMaintenance: sortedMaintenance.slice(0, 10) }));
  } else {
    let sortedSelected = sortMaintenance(
      selectedMaintenance,
      maintenanceSortOption,
      user,
      userRoles,
      companySettings,
    );
    yield put(setState({ selectedMaintenance: sortedSelected.slice(0, 10) }));
  }
}
