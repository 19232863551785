import { Issue } from '../CombinedEntry';
import moment from 'moment-timezone';
import { DutyScheduleEntry } from '../../../redux/pages/crew-scheduling-v3/scheduleEntries/types/DutyScheduleEntry';
import { DutyTimeEntry } from '../dutyEntries';

export interface DutyTimelineEntry {
  ID: string;
  UserID: string;
  StartTime: string;
  EndTime: string;
  RAPStartTime: string;
  RAPEndTime: string;
  PublishedAt: string;
  FlightDutyPeriodEndTime: string;
  Issues: Issue[];
  IsOverlapping: boolean;
  ManagementOverride: boolean;
  DutyDuration: string;
  HoursOfWork: string;
  FDPDuration: string;
  Version: number;
  NonFlyingDuty: boolean;
  IsUnpublished: boolean;
  PublishedVersionID: string;
  IsAwaitingForUpdate: boolean;
}

export const getDutyEntryStartEnd = (
  entry: DutyTimelineEntry | DutyScheduleEntry | DutyTimeEntry,
): [number, number] => {
  let start = moment(entry.StartTime).valueOf();
  let end = entry.EndTime ? moment(entry.EndTime).valueOf() : moment().valueOf();
  if (moment(start).isSame(moment(end)) && entry.RAPStartTime && entry.RAPEndTime) {
    start = moment(entry.RAPStartTime).valueOf();
    end = moment(entry.RAPEndTime).valueOf();
  }

  if (!moment(start).isSame(moment(end)) && entry.RAPStartTime && entry.RAPEndTime) {
    if (entry.RAPStartTime && moment(entry.RAPStartTime).valueOf() < start) {
      start = moment(entry.RAPStartTime).valueOf();
    }
    if (entry.RAPEndTime && moment(entry.RAPEndTime).valueOf() > end) {
      end = moment(entry.RAPEndTime).valueOf();
    }
  }

  return [start, end];
};
