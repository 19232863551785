import { put } from 'redux-saga/effects';
import LocalCache from '../../../../../../local-storage/local-storage';
import { scheduleActions } from '../../actions';
import { selectUserSaga } from '../../../../../../../common/saga/selectors';
import { selectEntryDisplayOptionsSaga } from '../../selectors-saga';
import { CrewTimelineEntryType } from '../../../../../../../common/types/timeline/CrewTimelineEntryType';
import { AircraftTimelineEntryType } from '../../../../../../../common/types/timeline/AircraftTimelineEntryType';
import { MaintenanceTimelineEntryType } from '../../../../../../../common/types/timeline/MaintenanceTimelineEntryType';

export function* setDisplayOptionsFromCache() {
  const user = yield* selectUserSaga();
  const localCache = new LocalCache('crewSchedulingV2', user);
  const defaultDisplayOptions = yield* selectEntryDisplayOptionsSaga();
  const cachedDisplayOption: string[] = localCache.getCached(
    'displayOptions',
    defaultDisplayOptions,
  );

  // a hack to reset the display options if the cache is corrupted
  if (cachedDisplayOption.length > 4) {
    yield put(scheduleActions.setEntryDisplayOptions(defaultDisplayOptions));
    return;
  }

  const displayOptionMapping: {
    [key: string]: CrewTimelineEntryType | AircraftTimelineEntryType | MaintenanceTimelineEntryType;
  } = {
    displayDutyScheduleEntries: CrewTimelineEntryType.DutySchedule,
    [CrewTimelineEntryType.DutySchedule]: CrewTimelineEntryType.DutySchedule,
    displayDutyTimeEntries: CrewTimelineEntryType.DutyTimes,
    [CrewTimelineEntryType.DutyTimes]: CrewTimelineEntryType.DutyTimes,
    displayPilotContracts: CrewTimelineEntryType.PilotContract,
    [CrewTimelineEntryType.PilotContract]: CrewTimelineEntryType.PilotContract,
    displayAircraftContracts: AircraftTimelineEntryType.AircraftContract,
    [AircraftTimelineEntryType.AircraftContract]: AircraftTimelineEntryType.AircraftContract,
    displayMaintenanceEntries: MaintenanceTimelineEntryType.MaintenanceCrew,
    [MaintenanceTimelineEntryType.MaintenanceCrew]: MaintenanceTimelineEntryType.MaintenanceCrew,
  };

  const updatedDisplayOptions = cachedDisplayOption
    .filter(displayOption => displayOptionMapping[displayOption])
    .map(displayOption => displayOptionMapping[displayOption]);

  yield put(scheduleActions.setEntryDisplayOptions(updatedDisplayOptions));
}
