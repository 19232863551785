import { CrewTimelineEntryType } from '../../../../../../../common/types/timeline/CrewTimelineEntryType';
import { call, delay } from 'redux-saga/effects';
import api from '../../../../../../../services/api';
import { selectCompanyIDSaga } from '../../../../../../../common/saga/selectors';
import { AircraftTimelineEntryType } from '../../../../../../../common/types/timeline/AircraftTimelineEntryType';
import { AircraftTimelineItem } from '../../../components/Timeline/AircraftTimeline/generator';
import moment from 'moment-timezone';
import { ContractTimelineItem } from '../../../components/Timeline/ContractTimeline/generator';
import { ContractTimelineEntryType } from '../../../../../../../common/types/timeline/ContractTimelineEntryType';
import { GenerateVirtualIDForPilotSchedule } from '../timelineEntryActions/handlePasteTimelineItem';
import { MaintenanceTimelineEntryType } from '../../../../../../../common/types/timeline/MaintenanceTimelineEntryType';

export function* fetchEntry(
  entryType:
    | CrewTimelineEntryType
    | AircraftTimelineEntryType
    | ContractTimelineEntryType
    | MaintenanceTimelineEntryType,
  entryID: string,
  isTimelineVersion: boolean,
) {
  const companyID = yield* selectCompanyIDSaga();
  let attempts = 0;
  const maxAttempts = 2;

  while (attempts < maxAttempts) {
    try {
      const {
        data: { Data },
      } = yield call(api.get, `/v1/companies/${companyID}/entry/${entryType}/${entryID}`, {
        params: { is_timeline_entry: isTimelineVersion },
      });

      return Data;
    } catch (error) {
      attempts += 1;
      if (attempts < maxAttempts) {
        yield delay(500);
      } else {
        throw new Error('Failed to fetch entry after 2 attempts');
      }
    }
  }
}

export function* fetchAircraftScheduleEntry(
  entryType: CrewTimelineEntryType | AircraftTimelineEntryType | ContractTimelineEntryType,
  item: AircraftTimelineItem | ContractTimelineItem,
  isTimelineVersion: boolean,
) {
  const companyID = yield* selectCompanyIDSaga();

  try {
    const {
      data: { Data },
    } = yield call(
      api.get,
      `/v1/companies/${companyID}/entry/${entryType}/${item.aircraftEntry.VirtualID}`,
      {
        params: {
          end_time__gt: moment(item.start_time).format(),
          start_time__lt: moment(item.end_time).format(),
          is_timeline_entry: isTimelineVersion,
        },
      },
    );

    return Data;
  } finally {
  }
}

export function* fetchGroupedCrewScheduleEntry(
  entryType: ContractTimelineEntryType,
  item: ContractTimelineItem,
  isTimelineVersion: boolean,
) {
  const companyID = yield* selectCompanyIDSaga();

  const virtualID = GenerateVirtualIDForPilotSchedule(item);

  try {
    const {
      data: { Data },
    } = yield call(api.get, `/v1/companies/${companyID}/entry/${entryType}/${virtualID}`, {
      params: {
        end_time__gt: moment(item.start_time).format(),
        start_time__lt: moment(item.end_time).format(),
        is_timeline_entry: isTimelineVersion,
      },
    });

    return Data;
  } finally {
  }
}
