import { EntryType } from '../../../../../../common/types/entryTypes';
import moment, { Moment } from 'moment-timezone';
import { call, put, select } from 'redux-saga/effects';
import actions, { setAircraftEntries, setAircraftLoading, setLoading, setState } from '../actions';
import { getCSMobile } from '../selectors';
import { getCommon, getCompanyID } from '../../../../../../redux/selectors';
import api from '../../../../../../services/api';
import { populateMonthDividers } from '../../helpers';
import { PreferredVersion } from '../../../../../../common/types/preferredVersoon';
import { AircraftEntry } from '../../../../../../common/types/CombinedEntry';

function* checkRangeLimits(from: Moment, to: Moment) {
  const { CompanyUsers } = yield select(({ user }) => user);

  const companySettings = yield select(({ user: { Company } }) => Company.Settings);
  const { publishedVersions, companyScheduleVersion, entryType } = yield select(getCSMobile);

  const preferredVersion: PreferredVersion = CompanyUsers[0]?.PreferredVersion || 'current';

  const isNotDutyTimes = entryType !== 'duty-times';
  if (
    isNotDutyTimes &&
    companySettings?.Versioning &&
    publishedVersions?.length > 0 &&
    preferredVersion &&
    preferredVersion !== 'latest' &&
    companyScheduleVersion
  ) {
    let selectedVersion = preferredVersion;
    if (preferredVersion === 'current') {
      selectedVersion = companyScheduleVersion.CurrentVersion;
    }

    const selectedPublishedVersion = publishedVersions?.find(
      pv => pv.Version === parseInt(selectedVersion as string, 10),
    );

    if (
      selectedPublishedVersion?.VisibleStart &&
      moment(selectedPublishedVersion?.VisibleStart).valueOf() > moment(from).valueOf()
    ) {
      from = moment(selectedPublishedVersion?.VisibleStart);
    }

    if (
      selectedPublishedVersion?.VisibleEnd &&
      moment(selectedPublishedVersion?.VisibleEnd).valueOf() < moment(to).valueOf()
    ) {
      to = moment(selectedPublishedVersion?.VisibleEnd);
    }
  }

  return [from, to];
}

export function* fetchAircraftEntries(
  entryType: EntryType,
  fromDate: Moment,
  toDate: Moment,
  showLoading: boolean,
) {
  try {
    yield put(setAircraftLoading(true));
    if (showLoading) {
      yield put(setLoading(true));
    }

    const [from, to] = yield checkRangeLimits(fromDate, toDate);

    yield put({
      type: actions.SET_STATE,
      payload: {
        isFutureVisibleLimitExceeded: to.isBefore(toDate),
      },
    });

    const { selectedAircraftId, selectedFilterAircraftIdentifiers } = yield select(getCSMobile);
    const { aircraftList } = yield select(getCommon);
    const companyID = yield select(getCompanyID);

    const aircraftIds =
      selectedFilterAircraftIdentifiers?.length === 0 || aircraftList?.length === 0
        ? selectedAircraftId
          ? [selectedAircraftId]
          : []
        : aircraftList
            ?.filter(a => selectedFilterAircraftIdentifiers.includes(a.TailNumber))
            .map(a => a.ID);

    const baseApiParams = {
      end_time__gt: from.format(),
      start_time__lt: to.format(),
      ongoing: true,
      limit: -1,
      aircraft_ids: aircraftIds,
    };

    const {
      data: { Data },
    } = yield call(api.get, `/v1/companies/${companyID}/${entryType}`, { params: baseApiParams });
    const sortedData: Record<string, AircraftEntry[]> = {};
    let relevantEntries = [];
    for (let key in Data) {
      if (aircraftIds.includes(key) && Data[key]) {
        sortedData[key] = Data[key].sort((a, b) => moment(a.StartTime).diff(moment(b.StartTime)));
        relevantEntries.push(...sortedData[key]); // Spread operator to push all entries
      }
    }
    relevantEntries.sort((a, b) => moment(a.StartTime).diff(moment(b.StartTime)));

    for (let key in sortedData) {
      const sortedArray = sortedData[key];
      for (let i = sortedArray.length - 1; i >= 0; i--) {
        const entry = sortedArray[i];
        if (entry.EndAirportID) {
          yield put(
            setState({
              defaultStartAirportID: entry.EndAirportID,
              defaultEndAirportID: entry.EndAirportID,
            }),
          );
          break;
        }
      }
    }

    for (let entry of relevantEntries) {
      entry.StartTime = new Date(entry.StartTime);
    }
    const combinedResults = populateMonthDividers(relevantEntries);

    combinedResults.forEach((entry: AircraftEntry) => {
      if (entry.AssignedAircraft) {
        if (entry.RelatedCrewScheduleEntries) {
          const pilotSet = new Set<string>();
          entry.Pilots = entry.RelatedCrewScheduleEntries.map(crew => crew.User).filter(pilot => {
            if (!pilotSet.has(pilot.ID)) {
              pilotSet.add(pilot.ID);
              return true;
            }
            return false;
          });
        }
      }
    });

    yield put(setAircraftEntries(combinedResults));
    yield put({
      type: actions.SET_STATE,
      payload: {
        hasMore: false,
      },
    });
  } finally {
    if (showLoading) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          isAircraftLoaded: true,
        },
      });
      yield put(setLoading(false));
    }
    yield put(setAircraftLoading(false));
  }
}
