import { AnyAction } from 'redux';
import { actionTypes } from './actions';
import { CommonTypes } from 'models';
import { SubPart } from '../../common/types/subPart';
import { Profile } from '../../common/types/user';
import { AircraftMinimal } from '../../common/types/aircraft';
import { CustomCurrencyItemTemplate } from '../../common/types/customCurrencyItem';
import { AircraftSelectorType } from './types';
import { CompanyScheduleType } from '../../common/types/CombinedEntry';
import { CompanyClient } from '../../common/types/client';
import { ClientContract } from '../../common/types/ClientContract';
import { Pilot } from '../../common/types/pilot';
import { CompanyDocument } from '../../common/types/companyDocument';

export interface CommonInitState {
  notifire: CommonTypes.Notifire | null;
  defaultAirports: CommonTypes.AirPort[];
  defaultSubParts: SubPart[];
  featureFlags: { [key: string]: boolean };
  isFeatureFlagsLoaded: boolean;
  companyClients: CompanyClient[];
  clientContracts: ClientContract[];
  isCompanyClientsLoaded: boolean;
  isDefaultCompanyClientsLoaded: boolean;
  pilotProfiles: Profile[];
  maintenanceProfiles: Profile[];
  allProfiles: Profile[];
  isPilotsLoaded: boolean;
  isMaintenanceLoaded: boolean;
  aircraftList: AircraftMinimal[];
  certificationTypeByCommonDesignator: { [key: string]: string };
  commonDesignatorsByCertificationType: { [key: string]: string[] };
  isAircraftListLoaded: boolean;
  isAircraftCertInitialized: boolean;
  aircraftIdentifiers: string[];
  aircraftSelectorType: AircraftSelectorType;
  aircraftBases: string[];
  customCurrencyItemTemplates: CustomCurrencyItemTemplate[];
  companyScheduleTypes: CompanyScheduleType[];
  defaultScheduleType: CompanyScheduleType;
  companyDocuments: CompanyDocument[];
  companyUsers: Pilot[];
}

const initialState: CommonInitState = {
  notifire: null,
  defaultAirports: [],
  defaultSubParts: [],
  featureFlags: {},
  isFeatureFlagsLoaded: false,
  companyClients: [],
  clientContracts: [],
  isCompanyClientsLoaded: false,
  isDefaultCompanyClientsLoaded: false,
  pilotProfiles: [],
  maintenanceProfiles: [],
  allProfiles: [],
  isPilotsLoaded: false,
  isMaintenanceLoaded: false,
  aircraftList: [],
  isAircraftListLoaded: false,
  certificationTypeByCommonDesignator: {},
  commonDesignatorsByCertificationType: {},
  isAircraftCertInitialized: false,
  aircraftIdentifiers: [],
  aircraftBases: [],
  customCurrencyItemTemplates: [],
  aircraftSelectorType: AircraftSelectorType.Registration,
  companyScheduleTypes: [],
  defaultScheduleType: null,
  companyDocuments: [],
  companyUsers: [],
};

export default function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case actionTypes.SET_STATE:
    case actionTypes.SET_CUSTOM_CURRENCY_ITEM_TEMPLATES:
    case actionTypes.SET_AIRCRAFT_TAIL_NUMBERS:
    case actionTypes.SET_AIRCRAFT_BASES:
    case actionTypes.SET_AIRCRAFT_SELECTOR_TYPE:
    case actionTypes.SET_DEFAULT_SCHEDULE_TYPE:
    case actionTypes.SET_COMPANY_SCHEDULE_TYPES:
    case actionTypes.SET_CLIENT_CONTRACTS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SET_LOAD_DEFAULT_AIRPORTS_TO_STATE:
      return {
        ...state,
        ...{
          defaultAirports: action.payload,
        },
      };
    case actionTypes.SET_NOTIFIRE:
      return {
        ...state,
        ...{
          notifire: action.payload,
        },
      };
    case actionTypes.CLEAR_NOTIFIRE:
      return {
        ...state,
        ...{
          notifire: null,
        },
      };
    case actionTypes.SET_FEATURE_FLAGS:
      return {
        ...state,
        ...{
          featureFlags: action.payload,
          isFeatureFlagsLoaded: true,
        },
      };
    case actionTypes.LOAD_COMPANY_CLIENTS:
      return {
        ...state,
        ...{
          companyClients: [...state.companyClients, ...action.payload.companyClients],
        },
      };
    case actionTypes.COMPANY_CLIENTS_LOADED:
      return {
        ...state,
        ...{
          isCompanyClientsLoaded: true,
        },
      };
    case actionTypes.DEFAULT_COMPANY_CLIENTS_LOADED:
      return {
        ...state,
        ...{
          isDefaultCompanyClientsLoaded: true,
        },
      };
    case actionTypes.SET_PILOT_PROFILES:
      return {
        ...state,
        ...{
          pilotProfiles: action.payload.pilotProfiles,
          isPilotsLoaded: true,
        },
      };
    case actionTypes.SET_MAINTENANCE_PROFILES:
      return {
        ...state,
        ...{
          maintenanceProfiles: action.payload.maintenanceProfiles,
          isMaintenanceLoaded: true,
        },
      };
    case actionTypes.SET_ALL_PROFILES:
      return {
        ...state,
        ...{
          allProfiles: action.payload.allProfiles,
        },
      };
    case actionTypes.SET_COMPANY_AIRCRAFT_LIST:
      return {
        ...state,
        ...{
          aircraftList: action.payload.aircraftList,
          isAircraftListLoaded: true,
        },
      };
    case actionTypes.SET_COMPANY_DOCUMENTS:
      return {
        ...state,
        ...{
          companyDocuments: action.payload.companyDocuments,
        },
      };
    case actionTypes.SET_COMPANY_USERS:
      return {
        ...state,
        ...{
          companyUsers: action.payload.companyUsers,
        },
      };
    case actionTypes.RESET_STATE:
      return initialState;
    default:
      return state;
  }
}
