import moment from 'moment-timezone';
import { call, put, select } from 'redux-saga/effects';
import api from '../../../services/api';
import actions, { setMaintenanceSortOption } from './actions';
import LocalCache from '../../../pages/local-storage/local-storage';
import { getUserRole } from '../../selectors';
import { ApiTypes } from '../../../models';
import { orderBy } from 'lodash';
import { CompanySettings } from '../../../common/types/Company';
import Profile = ApiTypes.Profile;
import { scheduleActions } from '../../../pages/data/organization/crew-scheduling-v3/redux-saga/actions';

type Maintenance = ApiTypes.MaintenanceUser;

export function* loadMaintenanceWorker() {
  const companySettings = yield select(({ user: { Company } }) => Company.Settings);
  const companyID = yield select(({ user: { Company } }) => Company.ID);
  const user = yield select(({ user }) => user);
  const { selectedTags, entryPeriod } = yield select(
    ({ pages: { crewSchedulingV2 } }) => crewSchedulingV2,
  );
  const userRoles = yield select(getUserRole) || [];
  const localCache = new LocalCache('crewSchedulingV2', user);

  const queryParams = { is_maintenance: true, limit: -1, order: 'profile.last_name asc' };
  if (selectedTags.length > 0) {
    queryParams['maintenance_schedule_tags'] = selectedTags;
    queryParams['user_tags'] = selectedTags;

    // TODO: API doesn't support this parameters yet
    // const [startTime, endTime] = entryPeriod;
    // if (startTime && endTime) {
    //   queryParams['crew_schedule_tags_date_start'] = startTime;
    //   queryParams['crew_schedule_tags_date_end'] = endTime;
    // }
  }

  const {
    data: { Data },
  } = yield call(api.get, `/v1/companies/${companyID}/users`, {
    params: queryParams,
  });

  if (!Data) {
    return {
      sortedMaintenance: [],
      selectedMaintenanceData: [],
      orderedByFirstNameMaintenance: [],
      maintenanceCertificationsData: [],
      maintenanceMemberBasesData: [],
    };
  }

  // get all unique base airports
  const maintenanceMemberBasesData = Array.from(
    new Set(Data.map((m: Maintenance) => m.Profile.BaseAirportCode)),
  ).filter(b => b);

  const certificationsMap = {};
  Data.forEach(maintenance => {
    if (maintenance.Profile.AMEAircraftCertifications) {
      maintenance.Profile.AMEAircraftCertifications.forEach(
        (certification: { AircraftType: string | number; Permissions: string[] }) => {
          if (!certificationsMap[certification.AircraftType]) {
            certificationsMap[certification.AircraftType] = [];
          }

          if (
            certification.Permissions &&
            certificationsMap[certification.AircraftType].indexOf(certification.Permissions[0]) < 0
          ) {
            certificationsMap[certification.AircraftType].push(certification.Permissions[0]);
          }
        },
      );
    }
  });
  const maintenanceCertificationsData = Object.keys(certificationsMap)
    .map(key => {
      return {
        value: key,
        label: key,
        children: certificationsMap[key]
          .map(el => ({ value: el, label: el }))
          .sort((a, b) => a.value.localeCompare(b.value)),
      };
    })
    .sort((a, b) => a.value.localeCompare(b.value));

  let maintenanceList = Data.filter(m => user?.id === m.ID || m.Profile.ShowInMXSchedule);
  let sortedMaintenance: Maintenance[];

  if (
    localCache.getCached('maintenanceSortOption') === 'custom' &&
    companySettings?.CustomUserDisplayOrder
  ) {
    sortedMaintenance = sortByCustomUserDisplayOrder(
      maintenanceList,
      user,
      userRoles,
      companySettings,
    );
  } else {
    sortedMaintenance = yield sortByCertifications(maintenanceList, user);
  }

  let selectedMaintenanceData = sortedMaintenance.slice(0, 10);
  const cachedMaintenanceMemberIds = localCache.getCached('selectedMaintenanceMemberIds', []);
  // if any maintenance members are selected in the local cache, set those in selectedMaintenance
  if (cachedMaintenanceMemberIds?.length > 0) {
    selectedMaintenanceData = sortedMaintenance.filter(m =>
      cachedMaintenanceMemberIds.includes(m?.ID),
    );
  }

  const orderedByFirstNameMaintenance = orderBy(Data, ['Profile.FirstName'], ['asc']);
  return {
    sortedMaintenance,
    selectedMaintenanceData,
    orderedByFirstNameMaintenance,
    maintenanceCertificationsData,
    maintenanceMemberBasesData,
  };
}

export function* loadMaintenance() {
  try {
    const {
      sortedMaintenance,
      selectedMaintenanceData,
      orderedByFirstNameMaintenance,
      maintenanceCertificationsData,
      maintenanceMemberBasesData,
    } = yield loadMaintenanceWorker();

    yield put({
      type: actions.LOAD_MAINTENANCE_SUCCESS,
      payload: sortedMaintenance,
    });

    yield put({
      type: actions.LOAD_SELECTED_MAINTENANCE_SUCCESS,
      payload: selectedMaintenanceData,
    });

    yield put({
      type: actions.LOAD_MAINTENANCE_ORDERED_BY_FIRST_NAME,
      payload: orderedByFirstNameMaintenance,
    });
    const user = yield select(({ user }) => user);
    const companySettings = yield select(({ user: { Company } }) => Company.Settings);
    const localCache = new LocalCache('crewSchedulingV2', user);
    let maintenanceSortOption: string;
    const maintenanceSortCache = localCache.getCached('maintenanceSortOption');
    if (!maintenanceSortCache) {
      if (companySettings.CustomUserDisplayOrder) {
        maintenanceSortOption = 'custom';
        yield call([localCache, 'setCached'], 'maintenanceSortOption', maintenanceSortOption);
        yield call(setMaintenanceSortOptionFromCache);
      } else {
        maintenanceSortOption = 'firstNameAlphabeticallyAsc';
      }
    } else {
      maintenanceSortOption = maintenanceSortCache;
    }
    yield put({
      type: actions.SET_STATE,
      payload: {
        maintenanceSortOption: maintenanceSortOption,
        maintenanceCertifications: maintenanceCertificationsData,
        maintenanceMemberBases: maintenanceMemberBasesData,
        lastRequestCalledTimestamp: moment(),
        isMaintenanceLoaded: true,
      },
    });
  } catch (error) {
    console.log(error);
  }
}

export const sortByCustomUserDisplayOrder = (
  maintenanceList,
  authUser,
  userRoles,
  companySettings,
) => {
  const authMaintenance = maintenanceList.find(maintenance => maintenance.ID === authUser.id);
  const onlyMaintenance = userRoles.includes('engineer') && !userRoles.includes('admin');

  if (
    (userRoles.includes('crewScheduleViewer') ||
      userRoles.includes('taggedScheduleViewer') ||
      companySettings.MaintenanceCanViewAllMaintenanceMembersSchedule) &&
    onlyMaintenance
  ) {
    maintenanceList = maintenanceList.filter(maintenance => maintenance.ID !== authUser.id);
  }

  const usersForSorting = maintenanceList.filter(
    maintenance => maintenance.Profile?.DisplayOrder > 0,
  );
  const remainingUsers = maintenanceList.filter(
    maintenance => maintenance.Profile?.DisplayOrder === 0 || !maintenance.Profile?.DisplayOrder,
  );

  let sortedMaintenanceList = usersForSorting.sort(
    (a, b) => a.Profile.DisplayOrder - b.Profile.DisplayOrder,
  );

  if (
    onlyMaintenance &&
    (userRoles.includes('crewScheduleViewer') ||
      userRoles.includes('taggedScheduleViewer') ||
      companySettings.MaintenanceCanViewAllMaintenanceMembersSchedule)
  ) {
    sortedMaintenanceList = [authMaintenance, ...sortedMaintenanceList].filter(m => m);
  } else if (!companySettings.MaintenanceCanViewAllMaintenanceMembersSchedule && onlyMaintenance) {
    sortedMaintenanceList = [authMaintenance];
  }

  // Concatenate the sorted and remaining users
  sortedMaintenanceList = [...sortedMaintenanceList, ...remainingUsers];

  return sortedMaintenanceList;
};

const sortByCertifications = function*(maintenanceArray: Maintenance[], authUser) {
  const userRoles = yield select(getUserRole) || [];
  const companySettings = yield select(({ user: { Company } }) => Company.Settings);
  const user = yield select(({ user }) => user);
  // sort maintenance by
  // 1. if company.Settings.CustomUserDisplayOrder, sort by this first
  // 2. Maintenance Certification asc
  // 3. Aircraft Type asc
  // 4. Date of Hire asc (nulls last)
  // 5. Last Name asc
  // 6. if role is (maintenance and not admin && (MaintenanceCanViewAllMaintenanceMembersSchedule)) show maintenance first

  const authMaintenance = maintenanceArray.find(m => m?.ID === authUser?.id);
  const onlyMaintenance = userRoles?.includes('engineer') && !userRoles?.includes('admin');
  const localCache = new LocalCache('crewSchedulingV2', authUser);

  if (
    (userRoles?.includes('crewScheduleViewer') ||
      userRoles?.includes('taggedScheduleViewer') ||
      companySettings?.MaintenanceCanViewAllMaintenanceMembersSchedule) &&
    onlyMaintenance
  ) {
    maintenanceArray = maintenanceArray.filter(m => m?.ID !== authUser?.id);
  }
  const maintenanceSortCache = localCache.getCached('maintenanceSortOption');
  if (maintenanceSortCache) {
    yield put(setMaintenanceSortOption(maintenanceSortCache));
  }
  let sortedMaintenance = yield sortMaintenance(
    maintenanceArray,
    maintenanceSortCache,
    user,
    userRoles,
    companySettings,
  );
  if (
    (userRoles?.includes('crewScheduleViewer') ||
      userRoles?.includes('taggedScheduleViewer') ||
      companySettings?.MaintenanceCanViewAllMaintenanceMembersSchedule) &&
    onlyMaintenance
  ) {
    sortedMaintenance = [authMaintenance, ...sortedMaintenance].filter(p => p);
  } else if (!companySettings?.MaintenanceCanViewAllMaintenanceMembersSchedule && onlyMaintenance) {
    sortedMaintenance = [authMaintenance];
  }
  return sortedMaintenance;
};

export const sortMaintenance = (
  maintenance: Maintenance[],
  maintenanceSortOption: string,
  authUser: Profile,
  userRoles: string[],
  companySettings: CompanySettings,
) => {
  let sortedMaintenance: Maintenance[];
  if (maintenanceSortOption === 'custom') {
    sortedMaintenance = sortByCustomUserDisplayOrder(
      maintenance,
      authUser,
      userRoles,
      companySettings,
    );
    return sortedMaintenance;
  }

  sortedMaintenance = maintenance.sort((a: Maintenance, b: Maintenance) => {
    if (!a.Profile.FirstName && !b.Profile.FirstName) {
      return 0; // Both names are null or undefined, consider them equal
    } else if (!a.Profile.FirstName) {
      return 1; // Null or undefined names are sorted to the end
    } else if (!b.Profile.FirstName) {
      return -1; // Null or undefined names are sorted to the end
    }
    if (maintenanceSortOption === 'firstNameAlphabeticallyAsc') {
      return a.Profile.FirstName.localeCompare(b.Profile.FirstName);
    } else if (maintenanceSortOption === 'lastNameAlphabeticallyAsc') {
      return a.Profile.LastName.localeCompare(b.Profile.LastName);
    } else if (maintenanceSortOption === 'firstNameAlphabeticallyDesc') {
      return b.Profile.FirstName.localeCompare(a.Profile.FirstName); // Descending sort for FirstName
    } else if (maintenanceSortOption === 'lastNameAlphabeticallyDesc') {
      return b.Profile.LastName.localeCompare(a.Profile.LastName); // Descending sort for LastName
    }
  });
  return [...sortedMaintenance];
};

export function* setMaintenanceSortOptionFromCache() {
  const user = yield select(({ user }) => user);
  const localCache = new LocalCache('crewSchedulingV2', user);
  const maintenanceSortCache = localCache.getCached('maintenanceSortOption');
  if (maintenanceSortCache) {
    yield put(setMaintenanceSortOption(maintenanceSortCache));
  } else {
    yield put(scheduleActions.setMaintenanceSortOption('firstNameAlphabeticallyAsc'));
  }
}
