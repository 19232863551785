import {
  SaveCSGroupedEntryAction,
  bulkModalEntryActions,
  DeleteCSGroupedEntryAction,
} from '../actions';
import { call, put } from 'redux-saga/effects';
import { scheduleActions } from '../../../../../redux-saga/actions';
import api from '../../../../../../../../../services/api';
import { selectCompanyIDSaga } from '../../../../../../../../../common/saga/selectors';
import MODAL_MODE from '../../../../../../../common/constants';
import { normalizeDateSeconds } from '../../../../../../../../../common/helpers/daysCalc';
import moment from 'moment-timezone';
import { BulkScheduleModalValues } from '../../../../types';
import { UpdateCrewScheduleBulkRequest } from '../types';

export function* handleDeleteCSGroupedEntry(action: DeleteCSGroupedEntryAction) {
  const companyID = yield* selectCompanyIDSaga();
  const { crewScheduleIDs, relatedAircraftIDs } = action.payload;

  const payload = {
    CrewScheduleEntryIds: crewScheduleIDs,
  };

  try {
    yield call(api.post, `/v1/companies/${companyID}/crewschedule/bulk-delete`, payload);
    yield put(bulkModalEntryActions.setContractModalMode({ open: false, mode: MODAL_MODE.create }));
    yield put(bulkModalEntryActions.setAircraftModalMode({ open: false, mode: MODAL_MODE.create }));
    yield put(scheduleActions.reFetchAircraftScheduleEntries(relatedAircraftIDs || []));
    yield put(scheduleActions.setEditAircraftScheduleEntry({}));
  } catch (err) {
    console.log(err);
    yield put(
      bulkModalEntryActions.setSaveError({
        title: 'Could Not Save Schedule Entry',
        message:
          'Please ensure this pilot is not already scheduled for this time. If this issue persists, contact support.',
      }),
    );
  } finally {
  }
}

const serializeEntry = (values: BulkScheduleModalValues): UpdateCrewScheduleBulkRequest => {
  const endTime = normalizeDateSeconds(moment(values.SchedulePeriod?.[1]));

  return {
    Notes: values.Notes,
    UserIDs: values.UserIDs,
    CrewScheduleEntryIds: values.CrewScheduleEntryIDs,
    StartTime: moment(values.SchedulePeriod?.[0])
      .seconds(0)
      .format(),
    EndTime: endTime.format(),
    Subparts: values.SubPart ? [values.SubPart] : null,
    StartAirportID: values.StartAirportID?.length > 0 ? values.StartAirportID : null,
    EndAirportID: values.EndAirportID?.length > 0 ? values.EndAirportID : null,
    AircraftID: values.AssignedAircraftID,
    CompanyScheduleTypeID: values.CompanyScheduleEntryTypeID,
    ClientContractID: values.ClientContractID,
  };
};
