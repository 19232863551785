import { all, put, takeEvery } from 'redux-saga/effects';
import { actions, setStateAction } from './actions';
import { initialState } from './reducers';

function* resetStateWorker() {
  yield put(setStateAction(initialState));
}

export default function* rootSaga() {
  yield all([takeEvery(actions.RESET_STATE, resetStateWorker)]);
}
