import MODAL_MODE from '../../../pages/data/common/constants';
import { Certificate } from '../../../pages/data/organization/tracked-items/constants';

const actions = {
  OPEN_MODAL: "pages/certificates/OPEN_MODAL",
  CLOSE_MODAL: "pages/certificates/CLOSE_MODAL",
  SET_CERTIFICATES: "pages/certificates/SET_CERTIFICATES"
}

export interface OpenCertificateModalAction {
  type: typeof actions.OPEN_MODAL;
  payload: { mode: MODAL_MODE };
}

export interface CloseCertificateModalAction {
  type: typeof actions.CLOSE_MODAL;
}

export interface SetCertificatesAction {
  type: typeof actions.SET_CERTIFICATES;
  payload: {
    certificates: Certificate[]
  }
}

export const setCertificatesAction = (certificates: Certificate[]): SetCertificatesAction => ({
  type: actions.SET_CERTIFICATES,
  payload: { certificates },
});

export const openCertificateModalAction = (mode: MODAL_MODE): OpenCertificateModalAction => ({
  type: actions.OPEN_MODAL,
  payload: { mode },
});

export const closeCertificateModalAction = (): CloseCertificateModalAction => ({
  type: actions.CLOSE_MODAL,
});

export default actions
