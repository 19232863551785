import {
  PusherActionType,
  PusherReduxAction,
} from '../../../../../../../../redux/pusher/PusherActions';
import { fetchEntry } from '../../timelineEntries/fetchEntry';
import { scheduleActions } from '../../../actions';
import { put, select } from 'redux-saga/effects';
import {
  selectMaintenanceScheduleTimelineEntriesSaga,
  selectPreferredVersionSaga,
} from '../../../selectors-saga';
import { NEW_ITEM_ID } from '../../../../components/Timeline/constants';
import { getRouter } from '../../../../../../../../redux/selectors';
import { RouterState } from 'connected-react-router';
import { isIgnoreUpdate } from '../helpers';
import { MaintenanceTimelineEntryType } from '../../../../../../../../common/types/timeline/MaintenanceTimelineEntryType';
import { MaintenanceTimelineEntry } from '../../../../../../../../common/types/timeline/MaintenanceEntryType';

export function* handleMaintenanceScheduleEntryCreate(
  action: PusherReduxAction<PusherActionType.MAINTENANCE_SCHEDULE_ENTRY_CREATED>,
) {
  const { location } = (yield select(getRouter)) as RouterState;
  if (!location.pathname.includes('crew-scheduling-v3')) {
    return;
  }

  const { payload } = action;
  const { ID, CrewScheduleSandboxID } = payload;
  const preferredVersion = yield* selectPreferredVersionSaga();
  if (isIgnoreUpdate(preferredVersion, CrewScheduleSandboxID)) {
    return;
  }

  try {
    const entry = (yield fetchEntry(
      MaintenanceTimelineEntryType.MaintenanceCrew,
      ID,
      true,
    )) as MaintenanceTimelineEntry;

    const existedEntries = yield* selectMaintenanceScheduleTimelineEntriesSaga();
    const updatedEntries = [...existedEntries.filter(e => e.ID !== NEW_ITEM_ID), entry];

    yield put(scheduleActions.setState({ maintenanceTimelineEntries: updatedEntries }));
    // yield put(scheduleActions.reFetchAircraftScheduleEntries(entry.AssignedAircraftIDs));
  } finally {
  }
}
