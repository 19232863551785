import { RouterState } from 'connected-react-router';
import { IntegrationsState } from 'redux/integrations/reducers';
import { Aircraft } from '../common/types/aircraft';
import { Company } from '../common/types/Company';
import { Pilot } from '../common/types/pilot';
import { CommonInitState } from './common/reducers';

export const getUser = ({ user }) => user;

export const getUserRole = ({ user: { CompanyUsers = [] } }) => CompanyUsers?.[0]?.Roles || [];

export const getSettings = ({ settings }) => settings;

export const getCompanyID = ({ user: { CompanyUsers = [] } }) =>
  CompanyUsers.map(x => x.CompanyID).find(x => x);

export const getCompany = ({ user: { Company } }) => Company as Company;
export const getCompanySettings = ({ user: { Company } }) =>
  Company.Settings as Company['Settings'];

export const getMenu = ({ menu }) => menu;

export const getAerochexEnabled = ({
  user: {
    Company: { AerochexEnabled },
  },
}) => AerochexEnabled;

export const getCampEnabled = ({
  integrations: { campConfig },
}: {
  integrations: IntegrationsState;
}) => Boolean(campConfig);

export const getAirportsPage = ({ pages: { airports } }) => airports;

export const getAircraftPage = ({ pages: { aircraft } }) => aircraft;

export const getAircraftDetailsPage = ({ pages: { aircraftDetails } }) =>
  aircraftDetails as { aircraft: Aircraft };

export const getAircraftLogDetailsPage = ({ pages: { aircraftLogDetails } }) => aircraftLogDetails;

export const getCompanyUsersPage = ({ pages: { users } }) => users;

export const getCompanyFlightsPage = ({ pages: { companyFlights } }) => companyFlights;

export const getAerochexPage = ({ pages: { aerochex } }) => aerochex;

export const getCrewSchedulingV2Page = ({ pages: { crewSchedulingV2 } }) => crewSchedulingV2;

export const getCrewSchedulingV2Pilots = ({ pages: { crewSchedulingV2 } }) =>
  crewSchedulingV2.pilots as Pilot[];

export const getCrewSchedulingV2AircraftIdentifiers = ({ pages: { crewSchedulingV2 } }) =>
  crewSchedulingV2.selectedAircraftIdentifiers as string[];

export const getDutyScheduleEntryModal = ({ pages: { dutyScheduleModal } }) => dutyScheduleModal;

export const getScheduleEntryModal = ({ pages: { scheduleEntryModal } }) => scheduleEntryModal;

export const getMaintenanceEntryModal = ({ pages: { maintenanceEntryModal } }) =>
  maintenanceEntryModal;

export const getDutyTimeModalEntryModal = ({ pages: { dutyTimeModal } }) => dutyTimeModal;

export const getReportsPage = ({ pages: { reports } }) => reports;

export const getNotifire = ({ common: { notifire } }) => notifire;

export const getDutyTimesPage = ({ pages: { dutyTimes } }) => dutyTimes;

export const getFlightTimesPage = ({ pages: { flightTimes } }) => flightTimes;

export const getCurrencyPage = ({ pages: { currency } }) => currency;

export const getCrewCompliancePage = ({ pages: { crewCompliance } }) => crewCompliance;

export const getDefaultAirports = ({ common: { defaultAirports } }) => defaultAirports;

export const getCommon = ({ common }) => common as CommonInitState;

export const getCompanyClients = state => getCommon(state).companyClients;
export const getIsDefaultCompanyClientsLoaded = state =>
  getCommon(state).isDefaultCompanyClientsLoaded;

export const getCompanyUser = ({ user: { CompanyUsers = [] } }) => CompanyUsers?.[0];

export const getRouter = ({ router }) => router as RouterState;
