import moment from 'moment-timezone';
import { call, put } from 'redux-saga/effects';
import { orderBy } from 'lodash';
import LocalCache from '../../../../../../local-storage/local-storage';
import api from '../../../../../../../services/api';
import { scheduleActions } from '../../actions';
import { Profile } from '../../../../../../../common/types/user';
import { CompanySettings } from '../../../../../../../common/types/Company';
import {
  selectCompanyIDSaga,
  selectCompanySettingsSaga,
  selectUserRoleSaga,
  selectUserSaga,
} from '../../../../../../../common/saga/selectors';
import { selectSelectedTagsSaga } from '../../selectors-saga';
import { MaintenanceSortOption } from '../../../types/sortOptions';
import { setMaintenanceSortOptionFromCache } from '../caching/setMaintenanceSortOptionFromCache';
import { MaintenanceUser } from '../../../../../../../common/types/maintenance';
import { CascaderOptions } from '../../../components/types';

export function* loadMaintenanceWorker() {
  const companySettings = yield* selectCompanySettingsSaga();
  const companyID = yield* selectCompanyIDSaga();
  const user = yield* selectUserSaga();
  const selectedTags = yield* selectSelectedTagsSaga();
  const userRoles = yield* selectUserRoleSaga();

  const localCache = new LocalCache('crewSchedulingV2', user);

  const queryParams = { is_maintenance: true, limit: -1, order: 'profile.last_name asc' };
  if (selectedTags.length > 0) {
    queryParams['maintenance_schedule_tags'] = selectedTags;
    queryParams['user_tags'] = selectedTags;
  }

  const {
    data: { Data },
  } = yield call(api.get, `/v1/companies/${companyID}/users`, {
    params: queryParams,
  });

  if (!Data) {
    return {
      sortedMaintenance: [],
      selectedMaintenanceData: [],
      orderedByFirstNameMaintenance: [],
      maintenanceCertificationsData: [],
      maintenanceMemberBasesData: [],
    };
  }

  // get all unique base airports
  let maintenanceBasesData: string[] = [];
  const filteredData = Data.filter((m: MaintenanceUser) => m.Profile.AMEAircraftCertifications?.length > 0);
  filteredData.forEach((m: MaintenanceUser) => {
    const base = m.Profile.BaseAirportCode;
    if (base) {
      maintenanceBasesData.push(base);
    }
  });

  const orderedByFirstNameMaintenance = orderBy(filteredData, ['Profile.FirstName'], ['asc']);

// Ensure only unique values are kept
  maintenanceBasesData = Array.from(new Set(maintenanceBasesData));

  const certificationsMap = {};
  filteredData.forEach(maintenance => {
    if (maintenance.Profile.AMEAircraftCertifications) {
      maintenance.Profile.AMEAircraftCertifications.forEach(certification => {
        if (!certificationsMap[certification.AircraftType]) {
          certificationsMap[certification.AircraftType] = [];
        }
        if (
          certification.Permissions.length &&
          certificationsMap[certification.AircraftType].indexOf(certification.Permissions[0]) < 0
        ) {
          certificationsMap[certification.AircraftType].push(certification.Permissions[0]);
        }
      });
    }
  });
  const maintenanceCertificationsData: CascaderOptions[] = Object.keys(certificationsMap)

    .map(key => {
      return {
        value: key,
        label: key,
        children: certificationsMap[key]
          .map(el => ({ value: el, label: el }))
          .sort((a, b) => a.value.localeCompare(b.value)),
      };
    })
    .sort((a, b) => a.value.localeCompare(b.value));
  let maintenanceList = filteredData.filter(p => user?.id === p.ID || p.Profile.ShowInMXSchedule);
  const onlyMaintenance = userRoles.includes('engineer') && !userRoles.includes('admin');
  if (
    onlyMaintenance &&
    !(
      userRoles.includes('crewScheduleViewer') ||
      userRoles.includes('taggedScheduleViewer') ||
      companySettings.MaintenanceCanViewAllMaintenanceMembersSchedule
    )
  ) {
    maintenanceList = filteredData.filter(maintenance => maintenance?.Profile?.UserID === user.id);
  }

  let sortedMaintenance: MaintenanceUser[];

  if (
    localCache.getCached('maintenanceSortOption') === 'custom' &&
    companySettings?.CustomUserDisplayOrder
  ) {
    sortedMaintenance = maintenanceList;
  } else {
    sortedMaintenance = maintenanceList;
  }

  let selectedMaintenanceData = sortedMaintenance.slice(0, 10);
  const cachedMaintenanceMemberIds = localCache.getCached('selectedMaintenanceIds', []);
  // if any maintenance members are selected in the local cache, set those in selectedMaintenance
  if (cachedMaintenanceMemberIds?.length > 0) {
    selectedMaintenanceData = sortedMaintenance.filter(m =>
      cachedMaintenanceMemberIds.includes(m?.ID),
    );
  }

  return {
    sortedMaintenance,
    selectedMaintenanceData,
    orderedByFirstNameMaintenance,
    maintenanceCertificationsData,
    maintenanceBasesData,
  };
}

export function* loadMaintenance() {
  try {
    const {
      sortedMaintenance,
      selectedMaintenanceData,
      orderedByFirstNameMaintenance,
      maintenanceCertificationsData,
      maintenanceBasesData,
    } = yield loadMaintenanceWorker();

    yield put(scheduleActions.loadSelectedMaintenanceSuccess(selectedMaintenanceData));

    const companySettings = yield* selectCompanySettingsSaga();
    const user = yield* selectUserSaga();

    const localCache = new LocalCache('crewSchedulingV2', user);
    let maintenanceSortOption: MaintenanceSortOption | null;
    const maintenanceSortCache = localCache.getCached('maintenanceSortOption');
    if (!maintenanceSortCache) {
      if (companySettings.CustomUserDisplayOrder) {
        maintenanceSortOption = 'custom';
        yield call([localCache, 'setCached'], 'maintenanceSortOption', maintenanceSortOption);
        yield call(setMaintenanceSortOptionFromCache);
      } else {
        maintenanceSortOption = 'firstNameAlphabeticallyAsc';
      }
    } else {
      maintenanceSortOption = maintenanceSortCache;
    }

    yield put(
      scheduleActions.setState({
        maintenance: sortedMaintenance,
        maintenanceFNOrder: orderedByFirstNameMaintenance,
        isMaintenanceLoaded: true,
        maintenanceSortOption: maintenanceSortOption,
        maintenanceCertifications: maintenanceCertificationsData,
        maintenanceMemberBases: maintenanceBasesData,
        lastRequestCalledTimestamp: moment(),
      }),
    );
  } catch (error) {
    console.log(error);
  }
}

export const sortByCustomUserDisplayOrder = (
  maintenanceList: Profile[],
  authUserID: string,
  userRoles: string[],
  companySettings: CompanySettings,
) => {
  const authMaintenance = maintenanceList.find(maintenance => maintenance.UserID === authUserID);
  const onlyMaintenance = userRoles.includes('engineer') && !userRoles.includes('admin');

  if (
    (userRoles.includes('crewScheduleViewer') ||
      userRoles.includes('taggedScheduleViewer') ||
      companySettings.MaintenanceCanViewAllMaintenanceMembersSchedule) &&
    onlyMaintenance
  ) {
    maintenanceList = maintenanceList.filter(maintenance => maintenance.UserID !== authUserID);
  }

  const usersForSorting = maintenanceList.filter(maintenance => maintenance?.DisplayOrder > 0);
  const remainingUsers = maintenanceList.filter(
    maintenance => maintenance?.DisplayOrder === 0 || !maintenance?.DisplayOrder,
  );

  let sortedMaintenanceList = usersForSorting.sort((a, b) => a.DisplayOrder - b.DisplayOrder);

  if (
    onlyMaintenance &&
    (userRoles.includes('crewScheduleViewer') ||
      userRoles.includes('taggedScheduleViewer') ||
      companySettings.MaintenanceCanViewAllMaintenanceMembersSchedule)
  ) {
    sortedMaintenanceList = [authMaintenance, ...sortedMaintenanceList].filter(m => m);
  } else if (!companySettings.MaintenanceCanViewAllMaintenanceMembersSchedule && onlyMaintenance) {
    sortedMaintenanceList = [authMaintenance];
  }

  // Concatenate the sorted and remaining users
  sortedMaintenanceList = [...sortedMaintenanceList, ...remainingUsers];

  return sortedMaintenanceList;
};

const sortByCertifications = function*(maintenanceArray: Profile[], authUser) {
  const companySettings = yield* selectCompanySettingsSaga();
  const user = yield* selectUserSaga();
  const userRoles = yield* selectUserRoleSaga();

  // sort maintenance by
  // 1. if company.Settings.CustomUserDisplayOrder, sort by this first
  // 2. Maintenance Certification asc
  // 3. Aircraft Type asc
  // 4. Date of Hire asc (nulls last)
  // 5. Last Name asc
  // 6. if role is (maintenance and not admin && (MaintenanceCanViewAllMaintenanceMembersSchedule)) show maintenance first

  const authMaintenance = maintenanceArray.find(m => m?.UserID === authUser?.id);
  const onlyMaintenance = userRoles?.includes('engineer') && !userRoles?.includes('admin');
  const localCache = new LocalCache('crewSchedulingV2', authUser);

  if (
    (userRoles?.includes('crewScheduleViewer') ||
      userRoles?.includes('taggedScheduleViewer') ||
      companySettings?.MaintenanceCanViewAllMaintenanceMembersSchedule) &&
    onlyMaintenance
  ) {
    maintenanceArray = maintenanceArray.filter(m => m?.UserID !== authUser?.id);
  }
  const maintenanceSortCache = localCache.getCached('maintenanceSortOption');
  if (maintenanceSortCache) {
    yield put(scheduleActions.setMaintenanceSortOption(maintenanceSortCache));
  }
  let sortedMaintenance = yield sortMaintenance(
    maintenanceArray,
    maintenanceSortCache,
    user.id,
    userRoles,
    companySettings,
  );
  if (
    (userRoles?.includes('crewScheduleViewer') ||
      userRoles?.includes('taggedScheduleViewer') ||
      companySettings?.MaintenanceCanViewAllMaintenanceMembersSchedule) &&
    onlyMaintenance
  ) {
    sortedMaintenance = [authMaintenance, ...sortedMaintenance].filter(p => p);
  } else if (!companySettings?.MaintenanceCanViewAllMaintenanceMembersSchedule && onlyMaintenance) {
    sortedMaintenance = [authMaintenance];
  }
  return sortedMaintenance;
};

export const sortMaintenance = (
  maintenance: Profile[],
  maintenanceSortOption: string,
  authUserID: string,
  userRoles: string[],
  companySettings: CompanySettings,
) => {
  let sortedMaintenance: Profile[];
  if (maintenanceSortOption === 'custom') {
    sortedMaintenance = sortByCustomUserDisplayOrder(
      maintenance,
      authUserID,
      userRoles,
      companySettings,
    );
    return sortedMaintenance;
  }

  sortedMaintenance = maintenance.sort((a: Profile, b: Profile) => {
    if (!a.FirstName && !b.FirstName) {
      return 0; // Both names are null or undefined, consider them equal
    } else if (!a.FirstName) {
      return 1; // Null or undefined names are sorted to the end
    } else if (!b.FirstName) {
      return -1; // Null or undefined names are sorted to the end
    }
    if (maintenanceSortOption === 'lastNameAlphabeticallyAsc') {
      return a.LastName.localeCompare(b.LastName);
    } else if (maintenanceSortOption === 'firstNameAlphabeticallyDesc') {
      return b.FirstName.localeCompare(a.FirstName); // Descending sort for FirstName
    } else if (maintenanceSortOption === 'lastNameAlphabeticallyDesc') {
      return b.LastName.localeCompare(a.LastName); // Descending sort for LastName
    } else{
      return a.FirstName.localeCompare(b.FirstName);
    }
  });

  return [...sortedMaintenance];
};
