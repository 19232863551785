import { put, select } from 'redux-saga/effects';
import { setState } from '../actions';
import LocalCache from '../../../../pages/local-storage/local-storage';
import { getUserRole } from '../../../selectors';
import { sortMaintenance } from '../loadMaintenance';

export function* watchSelectedMaintenance() {
  const { selectedMaintenanceMemberIds, maintenanceCrew, maintenanceSortOption } = yield select(
    ({ pages: { crewSchedulingV2 } }) => crewSchedulingV2,
  );
  const user = yield select(({ user }) => user);
  const userRoles = yield select(getUserRole) || [];
  const companySettings = yield select(({ user: { Company } }) => Company.Settings);
  const localCache = new LocalCache('crewSchedulingV2', user);
  if (selectedMaintenanceMemberIds.length === 0) {
    let sortedMaintenance = sortMaintenance(
      maintenanceCrew,
      maintenanceSortOption,
      user,
      userRoles,
      companySettings,
    );
    yield put(setState({ selectedMaintenance: sortedMaintenance }));
    localCache.setCached('selectedMaintenanceMemberIds', []);
    return;
  }

  const selectedMaintenance = maintenanceCrew.filter(({ ID }) =>
    selectedMaintenanceMemberIds.includes(ID),
  );
  let sortedMaintenance = sortMaintenance(
    selectedMaintenance,
    maintenanceSortOption,
    user,
    userRoles,
    companySettings,
  );
  yield put(setState({ selectedMaintenance: sortedMaintenance }));
  localCache.setCached('selectedMaintenanceMemberIds', selectedMaintenanceMemberIds);
}
