import { CrewTimelineEntryType } from './timeline/CrewTimelineEntryType';
import { Issue } from '../../redux/pages/crew-scheduling-v3/scheduleEntries/types/DutyScheduleEntry';
import { MaintenanceTimelineEntryType } from './timeline/MaintenanceTimelineEntryType';

export type EntryType =
  | CrewTimelineEntryType.CrewSchedule
  | CrewTimelineEntryType.DutySchedule
  | CrewTimelineEntryType.DutyTimes
  | CrewTimelineEntryType.AircraftSchedule
  | MaintenanceTimelineEntryType.MaintenanceCrew;

export const validTypes: EntryType[] = [
  CrewTimelineEntryType.CrewSchedule,
  CrewTimelineEntryType.DutySchedule,
  CrewTimelineEntryType.DutyTimes,
  CrewTimelineEntryType.AircraftSchedule,
  MaintenanceTimelineEntryType.MaintenanceCrew,
];

export type DutyValidationUpdateDto = {
  ID: string;
  IsChanged: boolean;
  Issues: Issue[];
};
