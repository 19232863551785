import { ValidationError } from '../interfaces';
import MODAL_MODE from '../../../../../pages/data/common/constants';

export const actions = {
  SET_STATE: 'crew-scheduling-v3/MaintenanceEntryModal/SET_STATE',
  SET_VALIDATION_SUGGESTIONS: 'crew-scheduling-v3/MaintenanceEntryModal/SET_VALIDATION_SUGGESTIONS',
  SET_ACCLIMATIZED_TIMEZONE_TAG:
    'crew-scheduling-v3/MaintenanceEntryModal/SET_ACCLIMATIZED_TIMEZONE_TAG',
  SET_CUSTOM_SECTION_VALUES: 'crew-scheduling-v3/MaintenanceEntryModal/SET_CUSTOM_SECTION_VALUES',
  OPEN_MODAL: 'crew-scheduling-v3/MaintenanceEntryModal/OPEN_MODAL',
  CLOSE_MODAL: 'crew-scheduling-v3/MaintenanceEntryModal/CLOSE_MODAL',
  RESET_STATE: 'crew-scheduling-v3/MaintenanceEntryModal/RESET_STATE',
};

export const setValidationSuggestionsAction = (suggestions: ValidationError[]) => ({
  type: actions.SET_VALIDATION_SUGGESTIONS,
  payload: { validationSuggestions: suggestions },
});

export const setAcclimatizedTimezoneTagAction = (acclimatizedTimezoneTag: string) => ({
  type: actions.SET_ACCLIMATIZED_TIMEZONE_TAG,
  payload: { acclimatizedTimezoneTag },
});

export const setCustomSectionValuesAction = (customSectionValues: any[]) => {
  return {
    type: actions.SET_CUSTOM_SECTION_VALUES,
    payload: { customSectionValues },
  };
};

export interface OpenMaintenanceScheduleModalAction {
  type: typeof actions.OPEN_MODAL;
  payload: { mode: MODAL_MODE };
}

export const openMaintenanceScheduleModalAction = (
  mode: MODAL_MODE,
): OpenMaintenanceScheduleModalAction => ({
  type: actions.OPEN_MODAL,
  payload: { mode },
});

export const closeMaintenanceScheduleModalAction = () => {
  return {
    type: actions.CLOSE_MODAL,
  };
};

export const setStateAction = payload => ({
  type: actions.SET_STATE,
  payload,
});

export const resetMaintenanceEntryModalStateAction = () => ({
  type: actions.RESET_STATE,
});
