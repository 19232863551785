import moment from 'moment-timezone';
import actions from './actions';
import { Tag, SelectedTimeline, ValidationStatus, AircraftLogByFlight } from './types/types';
import { DutyScheduleEntry } from './scheduleEntries/types/DutyScheduleEntry';
import { CrewSortOption } from '../../../pages/data/organization/crew-scheduling-v3/types/sortOptions';
import { MaintenanceScheduleEntry } from './scheduleEntries/types/MaintenanceScheduleEntry';

export const initialState = {
  pilots: [],
  selectedPilots: [],
  selectedAircraft: [],
  selectedClients: [],
  crewScheduleEntries: [],
  aircraftScheduleEntries: [],
  dutyScheduleEntries: [],
  dutyTimeEntries: [],
  aircraftContracts: [],
  pilotContracts: [],
  entryPeriod: [
    moment()
      .startOf('month')
      .subtract(30, 'days'),
    moment()
      .endOf('month')
      .add(60, 'days'),
  ],
  displayDutyScheduleEntries: true,
  displayDutyTimeEntries: true,
  displayPilotContracts: true,
  displayAircraftContracts: true,
  isAircraftScheduleUpdating: false,
  isUpdateAircraftContracts: false,
  updatePilotContracts: true,
  pilotViolationsMap: {},
  lastRequestCalledTimestamp: moment(),
  prevSelectedPilots: [],
  currentView: 'month',
  selectedTags: [],
  scheduleTypes: [],
  publishedVersions: null, // null - means not loaded, check condition to show <CalendarTimeline/> component
  publishedVersionsRaw: null,
  // todo: it's not clear why for CrewSchedule component we request pilots with the next order: 'profile.last_name asc'
  // todo: but for PilotContractModal, BulkScheduleEntryModal, ScheduleEntryModal the order is: profile.first_name asc,
  // todo: and in the CrewSchedule component we have additional sorting by p.Profile.ShowInCSV2
  // todo: right now keep pilotsFNOrder for backward compatibility, but merge the data later
  pilotsFNOrder: [],
  isPilotsLoaded: false,
  isMaintenanceLoaded: false,
  currentScheduleEntry: {}, // TODO: add type
  currentDutyScheduleEntry: {} as DutyScheduleEntry,
  currentMaintenanceScheduleEntry: {} as MaintenanceScheduleEntry,
  crewScheduleEntriesIsUpdating: false,
  dutyTimeEntriesIsUpdating: false,
  dutyScheduleEntriesIsUpdating: false,
  companyTags: [] as Tag[],
  entryTagToPilotsIDs: {} as Record<string, string[]>,
  selectedCrewMemberIds: [] as string[],
  selectedMaintenanceMemberIds: [] as string[],
  validatingEntries: {} as Record<string, ValidationStatus>,
  validationIteration: 0,
  updatedCrewScheduleEntry: null,
  updatedMaintenanceScheduleEntry: null,
  updatedScheduleEntry: null,
  updatedDutyTimeEntry: null,
  selectedAircraftIdentifiers: [] as string[],
  crewSortOption: null as CrewSortOption | null,
  isOverlapCheckActivated: false,
  isCheckingOverlap: false,
  isEntryOverlapping: false,
  selectedTimelines: [] as SelectedTimeline[],
  isLoadingAircraftLogsByFlight: false,
  aircraftLogsByFlight: [] as AircraftLogByFlight[],
  selectedCrewCertification: [] as string[],
  selectedCrewMemberBases: [] as string[],
  selectedAircraftBases: [] as string[],
  selectedAircraftTypes: [] as string[],
  activeCrewSchedulingMobileTab: null as string
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
    case actions.SET_INITIAL_STATE:
    case actions.SET_CURRENT_SCHEDULE_ENTRY:
    case actions.SET_CURRENT_MAINTENANCE_ENTRY:
    case actions.SET_PREV_SELECTED_PILOTS:
    case actions.SET_COMPANY_TAGS:
    case actions.SET_ENTRY_TAG_TO_PILOT_IDS:
    case actions.SET_SELECTED_TAGS:
    case actions.SET_SELECTED_CREW_MEMBER_IDS:
    case actions.SET_SELECTED_MAINTENANCE_MEMBER_IDS:
    case actions.SET_UPDATED_CREW_SCHEDULE_ENTRY:
    case actions.SET_UPDATED_SCHEDULE_ENTRY:
    case actions.SET_UPDATED_DUTY_TIME_ENTRY:
    case actions.SET_SELECTED_AIRCRAFT_IDENTIFIERS:
    case actions.SET_SELECTED_TIMELINES:
    case actions.SET_IS_AIRCRAFT_SCHEDULE_UPDATING:
    case actions.SET_IS_UPDATING_AIRCRAFT_CONTRACTS:
      return { ...state, ...action.payload };
    case actions.SET_VALIDATING_DUTY_SCHEDULE_ENTRIES:
      return { ...state, ...action.payload, validationIteration: state.validationIteration + 1 };
    case actions.SET_PUBLISHED_VERSION:
      return { ...state, publishedVersions: action.payload };
    case actions.SET_PUBLISHED_VERSION_RAW:
      return { ...state, publishedVersionsRaw: action.payload };
    case actions.ADD_PUBLISHED_VERSION:
      if (state.publishedVersions === null) {
        return { ...state, publishedVersions: [action.payload] };
      }
      return { ...state, publishedVersions: [action.payload, ...state.publishedVersions] };
    case actions.LOAD_PILOTS_SUCCESS:
      return { ...state, pilots: action.payload };
    case actions.LOAD_SELECTED_PILOTS_SUCCESS:
      return { ...state, selectedPilots: action.payload };
    case actions.LOAD_PILOTS_ORDERED_BY_FIRST_NAME:
      return { ...state, pilotsFNOrder: action.payload };
    case actions.SET_CURRENT_DUTY_SCHEDULE_ENTRY:
      return { ...state, currentDutyScheduleEntry: action.payload };
    case actions.SET_COMPANY_SCHEDULE_VERSION:
      return { ...state, companyScheduleVersion: action.payload };
    case actions.SET_PREFERRED_VERSION:
      return { ...state, preferredVersion: action.payload };
    case actions.UPDATE_ENTRY_PERIOD:
      return { ...state, entryPeriod: action.payload };
    case actions.RESET_CHECK_OVERLAPS:
      return { ...state, isOverlapCheckActivated: false, isEntryOverlapping: false };
    case actions.RESET_LOGS_FROM_FLIGHTS:
      return {
        ...state,
        isLoadingAircraftLogsByFlight: false,
        aircraftLogsByFlight: [],
        logsFromFlightsMessage: '',
      };
    default:
      return state;
  }
}
