import { DetailedFlightTime } from '../../../../../../../common/types/CombinedEntry';
import moment, { Moment } from 'moment-timezone';
import isFlightTimeWithinRestTime from '../../../../../common/helpers/isFlightsWithinRestTime';
import { editFields, resetValidationMsg, setValidationMsg } from '../../actions';
import { put } from 'redux-saga/effects';
import { composeKey } from '../../../helpers';

export function* checkRestFlightsOverlap(
  isSplitDuty: boolean,
  restStart: moment.Moment,
  restEnd: moment.Moment,
  flightTimes: DetailedFlightTime[],
) {
  const composedKey = composeKey(editFields.RestStart, editFields.RestEnd);

  if (isSplitDuty && flightTimes?.length) {
    const anyFlightWithinRestTime = flightTimes.some(time => {
      if (time.TimeOff && time.TimeOn) {
        return isFlightTimeWithinRestTime(
          moment(time.TimeOff),
          moment(time.TimeOn),
          restStart,
          restEnd,
        );
      }
    });

    if (anyFlightWithinRestTime) {
      yield put(
        setValidationMsg(composeKey(composedKey), 'At least one flight is within the rest time.'),
      );
    } else {
      yield put(resetValidationMsg(composeKey(composedKey)));
    }
  }
}
