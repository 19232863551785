import { call, put } from 'redux-saga/effects';
import api from '../../../services/api';
import actions from '../actions';
import { User } from '../../../common/types/CombinedEntry';

export function* loadMaintenanceWorker(companyID: string) {
  if (!companyID) {
    throw new Error('Failed to load maintenance. There are missed params.');
  }

  try {
    const queryParams = { limit: 1000, order: 'profile.last_name asc' };
    const {
      data: { Data },
    } = yield call(api.get, `/v1/companies/${companyID}/users`, {
      params: queryParams,
    });

    const adminProfiles = Data.filter(
      (u: { Profile: { AMELicenseNumber: string } }) => !u.Profile?.AMELicenseNumber,
    ).map((maintenance: User) => maintenance.Profile);

    const maintenanceProfiles = Data.filter(
      (u: { Profile: { AMELicenseNumber: string } }) => !!u.Profile?.AMELicenseNumber,
    ).map((maintenance: User) => maintenance.Profile);

    yield put(actions.setCompanyUsers(Data));
    yield put(actions.setMaintenanceProfiles(maintenanceProfiles));

    yield put(actions.setAllProfiles([...adminProfiles, ...maintenanceProfiles]));
  } catch (err) {
    console.log('Failed to load maintenance users: ', err);
  }
}
