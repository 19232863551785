import { ModalMode } from '../crew-scheduling-v3/modals/interfaces';
import MODAL_MODE from '../../../pages/data/common/constants';
import actions from './actions';
import { Qualification } from '../../../pages/data/organization/tracked-items/constants';

export interface QualificationModalInitialState {
  qualifications: Qualification[]
  modalMode: ModalMode;
}

const initialState: QualificationModalInitialState = {
  modalMode: { open: false, mode: MODAL_MODE.create },
  qualifications: []
};

export default function reducers(state: QualificationModalInitialState = initialState, action) {
  switch (action.type) {
    case actions.OPEN_MODAL:
      return { ...state, modalMode: { open: true, mode: action.payload.mode } };
    case actions.CLOSE_MODAL:
      return { ...state, modalMode: { open: false, mode: MODAL_MODE.create } };
    case actions.SET_QUALIFICATIONS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
