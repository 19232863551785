import { actions } from './actions';
import MODAL_MODE from '../../../../../pages/data/common/constants';
import { ModalMode } from '../interfaces';

export type MaintenanceScheduleModalInitialState = {
  validationSuggestions: string[];
  acclimatizedTimezoneTag: string;
  isValidationRunning: boolean;
  // validationState: string;
  customSectionValues: any[];
  modalMode: ModalMode;
};

export const initialState: MaintenanceScheduleModalInitialState = {
  validationSuggestions: [],
  acclimatizedTimezoneTag: '',
  isValidationRunning: false,
  // validationState: null,
  customSectionValues: [],
  modalMode: { open: false, mode: MODAL_MODE.create },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
    case actions.SET_CUSTOM_SECTION_VALUES:
      return { ...state, ...action.payload };
    case actions.OPEN_MODAL:
      return { ...state, modalMode: { open: true, mode: action.payload.mode } };
    case actions.CLOSE_MODAL:
      return { ...state, modalMode: { open: false, mode: MODAL_MODE.create } };
    default:
      return state;
  }
}
