import _ from 'lodash';
import { put } from 'redux-saga/effects';
import {
  selectAircraftScheduleTimelineEntriesSaga,
} from '../../selectors-saga';
import { scheduleActions } from '../../actions';
import { DeleteCrewFromAircraftAction } from '../../actions';

export function* handleDeleteCrewFromAircraft({
  payload,
}: DeleteCrewFromAircraftAction
) {
  const existingEntries = yield* selectAircraftScheduleTimelineEntriesSaga();

  const updatedEntries = existingEntries.map((entry) => {
    const payloadStartTime = new Date(payload.deletedCrewEntry.StartTime).getTime();
    const entryStartTime = new Date(entry.StartTime).getTime();
    const payloadEndTime = new Date(payload.deletedCrewEntry.EndTime).getTime();
    const entryEndTime = new Date(entry.EndTime).getTime();
    if (entry.AssignedAircraftIDs.some((id) => payload.assignedAircraftIDs?.includes(id)) && entryStartTime === payloadStartTime && entryEndTime === payloadEndTime) {
      return {
        ...entry,
        AssignedUserIDs: entry.AssignedUserIDs.filter((id) => id != payload.deletedCrewEntry.UserID),
      }
    }
    return entry;
  });

  const filteredUpdatedEntries = updatedEntries.filter((entry) => entry.AssignedUserIDs.length != 0);

  yield put(scheduleActions.setState({ aircraftScheduleTimelineEntries: filteredUpdatedEntries }));
}

